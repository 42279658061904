<template>
  <div class="home news HomePage2">
    <Header headerClass="blog-single-page" />

    <section id="blog-content-wrapper">
      <div class="container">
        <div class="blog-single-header">
        <h2 id="title"></h2>
          <div class="author-information">
            <span id="date"></span>
          </div>
          <div class="img-wrapper">
            <img id="img" src="" alt="">
          </div>
        </div>
        <div  id="content" class="blog-single-body">

        </div>
      </div>
    </section>

    <!--<section id="external-press">
      <div class="container">
        <h2 class="left">Recomended for you</h2>
        <div class="external-slider">
          <carousel :margin="32" :items="3" :autoplay="true" :responsive="{0:{items:1,nav:false, loop:true },600:{items:3,nav:true, loop:true }}">
            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-1.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>Yahoo! FInance</h3>
                  <p>Live Audited Stablecoin EURST Goes Live on Changelly PRO Exchange</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-2.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>Wall Street Italia</h3>
                  <p>Eurst, la stablecoin alternativa al mondo SEPA Swift nei pagamenti</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-3.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>The Cryptonomist</h3>
                  <p>Pay with crypto: the role of stablecoins in the future of E-commerce</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>

            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-1.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>Yahoo! FInance</h3>
                  <p>Live Audited Stablecoin EURST Goes Live on Changelly PRO Exchange</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-2.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>Wall Street Italia</h3>
                  <p>Eurst, la stablecoin alternativa al mondo SEPA Swift nei pagamenti</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="blog-card simple-blog-card">
                <div class="img-wrapper">
                  <img src="../assets/images/external-press-card-img-3.png" alt="How work">
                </div>
                <div class="content-wrapper">
                  <h3>The Cryptonomist</h3>
                  <p>Pay with crypto: the role of stablecoins in the future of E-commerce</p>
                  <a class="arrow-link" href="#">Read More</a>
                </div>
              </div>
            </div>

          </carousel>
        </div>
      </div>
    </section>-->

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
//import carousel from 'vue-owl-carousel';

export default {
  name: 'Home',
  components: {
    Header,
    Footer
    //carousel
  },
  data() {
    return {
      faqItems: [
        {
          title: 'What is a Tokash Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'What do you get when you become a Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Who can become a Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'How do I get paid for my sales?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
