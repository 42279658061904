<template>
  <div class="home affiliate bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="card" :playStore="true" primaryBannerTitle="Tokash <br/> Ambassadors"
            primaryBannerDescription="Become a Brand Ambassador" secondaryBannerImg="ambassadors"/>

    <section id="tokash-brand-ambassadors">
      <div class="container">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h2>What Is A Tokash Brand Ambassador?</h2>
              <p class="p">Tokash ambassadors help us spread the ideas and services
                of Tokash by showing their love and passion for our way of working and
                living. Our ambassadors are sociable individuals who are active in their
                communities and motivated to make the world a better place.</p>
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img class="tokashAmbassador" src="../assets/images/ambassadors2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="become-brand-ambassadors">
      <div class="container text-center">
        <h2>What Do You Get When You Become A Brand Ambassador?</h2>
        <p class="p">By becoming an ambassador and sharing more, you’ll
          have the chance to get free crypto exchange <br> transactions for 30 days,
          get a bonus in EURST tokens, or a free annual ELITE plan.</p>

        <div class="row">
          <div class="col-md-6">
            <div class="become-ambassadors-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/become-ambassadors-card-icon-1.svg" alt="How work">
              </div>
              <div class="content-wrapper">
                <h3>Crypto Exchange</h3>
                <p>Get free crypto exchange for 30 days</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="become-ambassadors-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/become-ambassadors-card-icon-2.svg" alt="How work">
              </div>
              <div class="content-wrapper">
                <h3>Crypto Bonunses</h3>
                <p>Get a bonus in EURST tokens</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="become-ambassadors-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/become-ambassadors-card-icon-3.svg" alt="How work">
              </div>
              <div class="content-wrapper">
                <h3>Discount</h3>
                <p>Get free annual ELITE plan</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="become-ambassadors-card">
              <div class="img-wrapper">
                <img src="../assets/images/exclusivecontent.png" alt="How work">
              </div>
              <div class="content-wrapper">
                <h3>Exclusive Content</h3>
                <p>Get an exclusive content that will help you build your story</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="how-affiliate-work" class="ambassador-affiliate-work">
        <div class="container">
          <h2 class="left">HOW IT WORKS</h2>
          <p class="p">Follow these 3 easy steps to join the <br>
            community of Tokash Brand Ambassadors</p>
          <a href="#" class="btn-style">Get Started</a>
          <div class="row">
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>1.</strong>
                </div>
                <div class="step-content">
                  <h3>Apply Easily</h3>
                <p class="p">Learn how to become a Tokash Brand Ambassador. Click "Get Started" to apply.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>2.</strong>
                </div>
                <div class="step-content">
                  <h3>Fill out the Questionnaire</h3>
                <p class="p">Fill out a super-simple Brand Ambassador questionnaire so we know more about you.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>3.</strong>
                </div>
                <div class="step-content">
                  <h3>Connect</h3>
                <p class="p">Sign up for our emails to stay up-to-date on important info like new product launches, special events and more.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <section id="faq">
      <div class="container">
        <h2 class="left">Frequently Asked Question</h2>
        <VueFaqAccordion
            :items="faqItems"
        />
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    VueFaqAccordion
  },
  data() {
    return {
      faqItems: [
        {
          title: 'What is a Tokash Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'What do you get when you become a Brand Ambassador?',
          value: 'By becoming an ambassador and sharing more, you’ll have the chance to get free crypto exchange transactions for 30 days, get a bonus in EURST tokens, or a free annual ELITE plan. ',
          category: 'Tab-1'
        },
        {
          title: 'Who can become a Brand Ambassador?',
          value: 'To become a Тokash ambassador, you must be active on LinkedIn and Twitter and over the age of 18. ',
          category: 'Tab-1'
        },
        {
          title: 'How do I get paid for my sales?',
          value: 'To make ambassador payments as easy and streamlined as possible, we pay our ambassadors commissions on their Tokash accounts. ',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
