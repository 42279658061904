<template>
  <div class="home">
    <Header/>

    <Banner secondaryBanner="active" externalLink="https://app.tokash.io/auth/sign-up" buttonLink="" primaryBannerTag="Dive Into Innovation" :playStore="false" :bannerForm="false" primaryBannerTitle="Tokenized Cash <br/> Payments for Digital <br/> Economy"
            primaryBannerDescription="Accept Payments, Send, Spend with Card, Redeem & Stake Your assets &
             digital assets in the fastest, safest, and most secure way like never before." secondaryBannerImg="home-banner-image-v2" id="home2-banner"/>


    <section id="services-reward">
      <div class="container">
        <div class="row row-ep-height">
          <div class="col-md-7 service-column">
            <div class="services-reward-card">
              <div class="img-wrapper">
                <img style="margin-left: 30px;" src="../assets/images/services-reward-staking-image.png" alt="">
              </div>
              <div class="content-wrapper">
                <h3 class="h3">Spend Stablecoins</h3>
                <p>Use your card worldwide and spend
                  multiple crypto <br> currencies with real-time
                  conversion at point-of-sale.</p>
                <RouterLink to="card" class="btn-style primary-arrow">Learn more</RouterLink>
              </div>
            </div>
          </div>
          <div class="col-md-5 service-column">
            <div class="services-reward-card">
              <div class="content-wrapper">
                <h3 class="h3">Rewards</h3>
                <p>With the loyalty program you get a
                  token back to your account with every
                  card top up.</p>
                  <RouterLink to="product" class="btn-style primary-arrow">Learn more</RouterLink>
              </div>
              <div class="img-wrapper">
                <img src="../assets/images/services-reward-reward-image2.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="row row-ep-height">
          <div class="col-md-5 service-column">
            <div class="services-reward-card">
              <div class="content-wrapper">
                <h3 class="h3">Staking</h3>
                <p>Lock your stablecoins easily and earn up to 14% passive income.</p>
                <RouterLink to="staking" class="btn-style primary-arrow">Learn more</RouterLink>
              </div>
              <div class="img-wrapper">
                <img style="max-height: 270px;" src="../assets/images/stakinghome.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-md-7 service-column">
            <div class="services-reward-card custody">
              <div class="img-wrapper">
                <img style="margin-left: auto;" src="../assets/images/services-reward-custody-image.png" alt="">
              </div>
              <div class="content-wrapper">
                <h3 class="h3">Custody</h3>
                <p>Hold and safeguard your digital assets with Tokash.</p>
                  <RouterLink to="product" class="btn-style primary-arrow">Learn more</RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="four-step-registration">
      <div class="container">
        <div class="registration-steps-card-wrapper">
          <h2>Easy registration only 4 steps</h2>
          <div class="row row-eq-height">
            <div class="col-md-3">
              <div class="registration-steps-card">
                <div class="img-wrapper">
                  <img src="../assets/images/Tokash web_1.png" alt="">
                  <!--<span>1</span>-->
                </div>
                <div class="content-wrapper">
                  <h3>Sign Up</h3>
                  <p>Create your individual or business profile</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="registration-steps-card">
                <div class="img-wrapper">
                  <img src="../assets/images/Tokash web_2.png" alt="">
                  <!--<span>2</span>-->
                </div>
                <div class="content-wrapper">
                  <h3>KYC Process</h3>
                  <p>Complete the profile verification
                    process to confirm your identity and
                    get ready to start.</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="registration-steps-card">
                <div class="img-wrapper">
                  <img src="../assets/images/Tokash web_3.png" alt="">
                  <!-- <span>3</span> -->
                </div>
                <div class="content-wrapper">
                  <h3>Create Accounts</h3>
                  <p>Generate your cryptocurrency wallets and open your dedicated IBAN account easy.</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="registration-steps-card">
                <div class="img-wrapper">
                  <img src="../assets/images/Tokash web_4.png" alt="">
                  <!-- <span>4</span> -->
                </div>
                <div class="content-wrapper">
                  <h3>Create & Order A Card</h3>
                  <p>Create a virtual and order a
                    physical debit card linked
                    to your profile.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="currencies-slider">
      <div class="container">
        <h2 class="left">Currencies</h2>
      </div>
      <carousel :margin="32" :items="3" :stagePadding="130" :autoplay="true" :responsive="{0:{items:1,nav:false, loop:true, stagePadding:40, margin:16 },768:{items:2, stagePadding:40,nav:true, loop:true },1600:{items:3, stagePadding:130,nav:true, loop:true }}">
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currencies-slider-img-2.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">An asset-backed stablecoin</span>
              <h3>EURST</h3>
              <p>Hold your money in EUR value while being in <br> crypto.</p>
              <a href="https://eurst.io" target="_blank" class="primary-arrow">Eurst.io</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/usdtflat.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Tether token the world's First Stablecoin</span>
              <h3>USDT</h3>
              <p>Tether tokens are built on multiple blockchains — offering easy integration and adoption.</p>
              <a href="https://tether.to" target="_blank" class="primary-arrow">tether.to</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currencies-slider-img-1.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">A fiat to blockchain solution</span>
              <h3>USDC</h3>
              <p>USDC enjoys all the advantages of being an Ethereum-based currency.</p>
              <a href="https://www.centre.io/usdc" target="_blank" class="primary-arrow">www.centre.io/usdc</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-busd.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">A fiat-backed stablecoin pegged to the US dollar</span>
              <h3>Binance USD</h3>
              <p>A secure and stable cryptocurrency on the Ethereum blockchain.</p>
              <a href="https://www.binance.com/it/busd" target="_blank" class="primary-arrow">binance.com/en/busd</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-dai.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The world’s first unbiased currency</span>
              <h3>DAI</h3>
              <p>Financial freedom with no volatility.</p>
              <a href="https://makerdao.com/" target="_blank" class="primary-arrow">makerdao.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-usdp.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The world’s leading regulated stablecoin</span>
              <h3>Pax Dollar</h3>
              <p>A digital dollar that always equals a dollar.</p>
              <a href="https://makerdao.com/" target="_blank" class="primary-arrow">makerdao.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-tusd.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The first regulated stablecoin fully backed by the US Dollar</span>
              <h3>TrueUSD</h3>
              <p>It has become the national digital currency of Dominica.</p>
              <a href="https://www.trueusd.com/" target="_blank" class="primary-arrow">trueusd.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-usdd.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Decentralized USD</span>
              <h3>USDD</h3>
              <p>Over-Collateralized Decentralized Stablecoin.</p>
              <a href="https://usdd.io/" target="_blank" class="primary-arrow">usdd.io</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-gusd.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">A regulated stable value coin</span>
              <h3>Gemini Dollar</h3>
              <p>1:1 USD-backed stablecoin issued by Gemini.</p>
              <a href="https://www.gemini.com/dollar" target="_blank" class="primary-arrow">gemini.com/dollar</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-fei.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Fei Protocol</span>
              <h3>FEI</h3>
              <p>Backed 1:1 by the DAI stablecoin. </p>
              <a href="https://fei.money/" target="_blank" class="primary-arrow">fei.money</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currencies-slider-img-5.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The first and Most Valuable Cryptocurrency in the World</span>
              <h3>BITCOIN</h3>
              <p>
                Bitcoin is an innovative payment network and a new kind of money.
            </p>
              <a href="https://bitcoin.org" target="_blank" class="primary-arrow">Bitcoin.org</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currencies-slider-img-3.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Fast transaction on the Ethereum Network</span>
              <h3>ETHEREUM</h3>
              <p>The community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications.</p>
              <a href="https://Ethereum.org" target="_blank" class="primary-arrow">Ethereum.org</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-xrp.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The seventh-largest cryptocurrency</span>
              <h3>Ripple</h3>
              <p>Utility for the new global economy.</p>
              <a href="https://ripple.com" target="_blank" class="primary-arrow">ripple.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-ada.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The third-generation blockchain</span>
              <h3>Cardano</h3>
              <p>A new type of currency. A new means of transaction.</p>
              <a href="https://cardano.org/" target="_blank" class="primary-arrow">cardano.org</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-dot.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The first fully-sharded blockchain</span>
              <h3>Polkadot </h3>
              <p>Less trust more truth.</p>
              <a href="https://polkadot.network/" target="_blank" class="primary-arrow">polkadot.network</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-ltc.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">The future of money</span>
              <h3>LTC</h3>
              <p>The cryptocurrency for payments. </p>
              <a href="https://litecoin.com/" target="_blank" class="primary-arrow">litecoin.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-sol.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">A new architecture for a high performance blockchain</span>
              <h3>Solana</h3>
              <p>Powerful for developers. Fast for everyone.</p>
              <a href="https://solana.com/" target="_blank" class="primary-arrow">solana.com</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-uni.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Peer-to-peer system on the Ethereum blockchain</span>
              <h3>Uniswap</h3>
              <p>The leading decentralized exchange (DEX) by trading volume.</p>
              <a href="https://uniswap.org/" target="_blank" class="primary-arrow">uniswap.org</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-bch.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">Peer-to-Peer Electronic Cash</span>
              <h3>Bitcoin Cash</h3>
              <p>Better money for the world.</p>
              <a href="https://www.bitcoincash.org/" target="_blank" class="primary-arrow">bitcoincash.org</a>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="currencies-card">
            <div class="img-wrapper">
              <img src="../assets/images/currency-dash.png" alt="How work">
            </div>
            <div class="content-wrapper">
              <span class="tag">A payments-focused cryptocurrency</span>
              <h3>DASH</h3>
              <p>Your money, your way.</p>
              <a href="http://dash.org/" target="_blank" class="primary-arrow">dash.org</a>
            </div>
          </div>
        </div>
      </carousel>
    </section>

    <section id="about-tokash-v2">
      <div class="container">
        <div class="content-wrapper">
          <strong class="primary-bg">
            ABOUT TOKASH
          </strong>
          <h2 class="h2">Tokash is the meeting point <br> between real assets and <br> digital assets.</h2>
          <p>Using an asset-backed stablecoins, gives you all the benefits of a bank, money
            transfer and a cryptocurrency exchange, for faster, cheaper and board-less
            transactions without any risk.</p>
          <p>Unlike other digital money, you have full control of your money in the
            blockchain as if you were holding a physical monetary note.</p>
        </div>
      </div>
    </section>

    <section id="why-tokash-v2">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="why-tokash-card">
<!--              <strong class="primary-bg">WHY TOKASH</strong>-->
              <h2>No Volatility</h2>
              <p>One of the benefits of Tokash is that it doesn’t have volatility.
                Our stablecoins protect customers' capital from high market volatility,
                and facilitate transfers to individuals or companies.</p>
            </div>
            <div class="why-tokash-card">
<!--              <strong class="primary-bg">WHY TOKASH</strong>-->
              <h2>Crypto Transaction <br> Monitoring</h2>
              <p>With transaction monitoring we track customer transactions
                that include evaluating past-current customer information and
                interactions to provide a clear and compliant transaction ecosystem.</p>
            </div>
            <div class="why-tokash-card">
<!--              <strong class="primary-bg">WHY TOKASH</strong>-->
              <h2>Borderless</h2>
              <p>Buy or transfer your digital assets in minutes
                anywhere in the world with our stablecoins.</p>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
        <img src="../assets/images/why-tokash-v2-image.png" class="why-tokash-v2-image" alt="">
      </div>
    </section>

    <section id="join-borderless-innovation">
      <div class="container">
        <div class="borderless-innovation-wrapper">
          <div class="content-wrapper">
            <strong>Join The Borderless Innovation</strong>
            <h2>AND # TOKENized <br>
              Ecosystem.</h2>
            <p class="p">Join the TOKASH payment rails,
              unlock and increase <br>your cash flow and wealth.</p>
            <ul class="app-store home">
              <li><a href="https://play.google.com/store/apps/details?id=com.tokash"><img src="../assets/images/playstore-img.png" alt=""></a></li>
              <li><a href=""><img src="../assets/images/appstore-img.png" alt=""></a></li>
            </ul>
          </div>
          <div class="coin-slider">
            <carousel :margin="16" :items="2" :autoplay="true" :responsive="{0:{items:7,nav:false, loop:true },600:{items:7,nav:true, loop:true }}">
              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-1.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-2.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-3.png" alt="">
                </div>
              </div>
              <!--<div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-4.svg" alt="">
                </div>
              </div>-->
<!--              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-5.svg" alt="">
                </div>
              </div>-->
              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-6.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img src="../assets/images/coin-slider-img-7.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg" src="../assets/images/litecoin.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg" src="../assets/images/ripple.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg2" src="../assets/images/busd.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg2" src="../assets/images/btcash.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg2" src="../assets/images/cardano.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg2" src="../assets/images/polkadot.png" alt="">
                </div>
              </div>
              <div class="item">
                <div class="img-wrapper">
                  <img class="newCoinImg2" src="../assets/images/uniswap.png" alt="">
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </section>

    <section id="partnership">
      <div class="container">
        <h2>Partnership</h2>
        <div class="row row-eq-height">
          <div class="col-md-6">
            <div class="why-tokash-card">
              <div class="content-wrapper">
                <h3 class="h3">Partnership Inquiry</h3>
                <p class="p">We work with various merchants, businesses, causes,
                  and others on the adoption of stablecoins and in showcasing the
                  benefits of TOKASH. We are on a mission to promote transparency,
                  high standards of compliance and the various uses of stable coins.
                  If your vision aligns with ours, we’ll be glad to talk.</p>
                <a class="arrow-link" href="https://k9654f4dt2i.typeform.com/to/BVJ86WlB">START INQUIRY</a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="why-tokash-card">
              <div class="content-wrapper">
                <h3 class="h3">Institutional Inquiry</h3>
                <p class="p">Enter the digital assets sector and expand your portfolio
                  by adopting reliable stablecoins. The digital assets space is
                  yet in its early stage and is set for rapid growth.
                  is engineered with a secure institutional level structure, and
                  the highest compliance standards, which assure institutions that
                  they can incorporate reliable stablecoins for deposits, transactions,
                  and card payments.</p>
                <a class="arrow-link" href="https://k9654f4dt2i.typeform.com/to/BVJ86WlB">START INQUIRY</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about-stablecoins" class="news">
      <div class="container">
        <h2>About Stablecoins</h2>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="stablecoins-card">
              <a href="#" id="art1">
              <div class="img-wrapper">
                <img id="art1img" src="">
              </div>
              <div class="content-wrapper">
                <h3><a href="#" id="art1title"></a></h3>
              </div>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="stablecoins-card">
              <a href="#" id="art2">
              <div class="img-wrapper">
                <img id="art2img" src="">
              </div>
              <div class="content-wrapper">
                <h3><a href="#" id="art2title"></a></h3>
              </div></a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="stablecoins-card">
              <a href="#" id="art3">
              <div class="img-wrapper">
                <img id="art3img" src="">
              </div>
              <div class="content-wrapper">
                <h3><a href="#" id="art3title"></a></h3>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import carousel from 'vue-owl-carousel';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    carousel,
    Banner
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
