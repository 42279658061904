<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" primaryBannerTag="About Us" primaryBannerTitle="Bridging Fiat <br/> Assets and Digital <br/> Assets"
            primaryBannerDescription="Become a Brand Ambassadors" secondaryBannerImg="about-man-banner-img"/>

    <section id="about-ceo" class="SecondaryPage">
      <div class="container text-center">
<!--        <div class="img-wrapper">
          <img src="../assets/images/about-ceo-image.png" alt="image">
        </div>-->
        <div class="content-wrapper">
          <h3 class="h3"><strong>"We accelerated the payments by tokenizing the real currency bringing to the next transaction level"</strong></h3>
          <h3 class="h3">Founder & CEO</h3>
          <p class="p">Simone Mazzuca</p>
        </div>
      </div>
    </section>

    <section id="about-how-it-work-primary">
      <div class="container">
        <h2>Working together for a better payment system <br>
          and to generate a healthier economy</h2>
        <div class="row">
          <div class="col-md-6">
            <div class="why-tokash-card">
              <div class="content-wrapper">
                <h3 class="h3">How We Started</h3>
                <p>Stablecoins are built as a result of savouring all
                  the benefits of the digital assets, and the necessary
                  security and transparency pillars, which can allow institutions
                  and businesses to start accepting stablecoins safely.</p>
              </div>
              <div class="img-wrapper">
                <img style="margin-right: 8px;" src="../assets/images/how-we-started.png" alt="How work">
              </div>
            </div>

            <div class="why-tokash-card">
              <div class="content-wrapper">
                <h3 class="h3">Vision</h3>
                <p>We put transparency first for a healthy economy, and make sure
                  that stablecoins provide all of the necessary assurances for the
                  users that they bring the best of the digital assets benefits, while
                  staying secure and compliant. We believe stablecoins are the bridge
                  between the traditional assets and digital assets space, and only the
                  products which put the users and transparency first can achieve the required result.</p>
              </div>
              <div class="img-wrapper">
                <img style="max-width: 320px;" src="../assets/images/better-economy-tokash_1.png" alt="How work">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="why-tokash-card mission">
              <div class="content-wrapper">
                <h3 class="h3">Mission</h3>
                <p>Our mission is to provide today’s market and institutions, with
                  the most reliable and transparent stablecoins, which can be used
                  on large scale. We aim to give the benefit to all unbanked users
                  to be able to transact globally - fast, easy and with low fees.</p>
              </div>
              <div class="img-wrapper">
                <img src="../assets/images/target.png" alt="How work">
              </div>
            </div>
            <div class="why-tokash-card">
              <div class="content-wrapper">
                <h3 class="h3">Goals</h3>
                <p>TOKASH is working towards enabling businesses, merchants and institutions
                  to integrate stablecoins. We are working to meet the regulators’ and institutions’
                  expectations for the better understanding and implementation of the stablecoins as
                  a payment method, and we aim to let businesses and institutions to start accepting
                  stablecoins on a large scale.</p>
              </div>
              <div class="img-wrapper">
                <img src="../assets/images/goals.png" alt="How work">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about-partners">
      <div class="container text-center">
        <h2>PARTNERS</h2>
        <ul>
          <li><a target="_blank" href="https://eurst.io/"><img style="max-width: 120px;" src="../assets/images/eurst.png" alt=""></a></li>
        </ul>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>
