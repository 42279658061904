<template>
  <div class="home bluebg">
    <Header/>

    <Banner atmBanner="active" primaryBannerTitle="ATM MAP"
            primaryBannerDescription="Use our map to find all ATM provided by Tokash. <br><br>
            Select the service and cryptocurrencies you are interested in, <br> to find out the opening hours and more info."/>
<!--
    <section id="atm-steps">
      <div class="container">
        <h2>What You Get</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/atm-card-icon-1.svg" alt="">
              </div>
              <div class="content-wrapper">
                <h3>10 ATMs</h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/atm-card-icon-2.svg" alt="">
              </div>
              <div class="content-wrapper">
                <h3>1 Country</h3>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/atm-card-icon-3.svg" alt="">
              </div>
              <div class="content-wrapper">
                <h3>10+ Crypto</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="atm-exchange-cryptocurrency">
      <div class="container">
        <h2>Find Where To Exchange Cryptocurrency For Cash.</h2>
        <div class="atm-plan-button">
            <span class="switcher switcher-1">
              <input type="checkbox" id="switcher-1">
              <label for="switcher-1"></label>
            </span>
        </div>
      </div>
    </section>


    
    <section id="atm-map">
       <iframe src="https://newtokash.hexcom.solutions/mapsNew/index.html"></iframe>
    </section>
    -->

    <section class="coming">
      <h2>Coming soon</h2>
      <div class="subtitle" style="max-width: 400px;">
            <div class="row">
                <div class="col">
                    <h5>Days</h5>
                </div>
                <div class="col">
                    <h5>Hours</h5>
                </div>
                <div class="col">
                    <h5>Minutes</h5>
                </div>
                <div class="col">
                    <h5>Seconds</h5>
                </div>
            </div>

            <br>

            <div class="row">
                <div class="col">
                    <div>
                    <h5 class="timelement" id="days"></h5>
                    </div>
                </div>
                <div class="col">
                    <h5 class="timelement" id="hours"></h5>
                </div>
                <div class="col">
                    <h5 class="timelement" id="minutes"></h5>
                </div>
                <div class="col">
                    <h5 class="timelement" id="seconds"></h5>
                </div>
            </div>
            <br/>

            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6" style="font-size: 24px;">
                <h4 class="text-center">on 12/02/23</h4>
              </div>
            </div>
    </div>
    </section>

    <section id="faq" class="atm-faq" style="display: none;">
      <div class="container">
        <h2>Frequently Asked Question</h2>
        <VueFaqAccordion
            :items="faqItems"
        />
      </div>
    </section>
    

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    VueFaqAccordion
  },
  data() {
    return {
      faqItems: [
        {
          title: 'How to use our crypto ATM?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'How much can I withdraw?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Are there fees for buying & selling crypto?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
