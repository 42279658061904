<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="atm" primaryBannerTag="Flexible" :playStore="false" :bannerForm="false" primaryBannerTitle="Pricing"
            primaryBannerDescription="Earn 10 EURST when your friends make a deposit of 100 EUR." secondaryBannerImg="payments" id="pricing-banner"/>

    <section id="choose-your-plan">
      <div class="container">
        <h2>Choose Your Plan</h2>
        <div class="choose-plan-button">
          <div class="switch switch--horizontal">
            <input  v-model="isActiveTab"  v-bind:value="'active-tab-1'"
                    id="radio-a" type="radio" name="first-switch" checked="checked"/>
            <label for="radio-a">Monthly plan</label>
            <input  v-model="isActiveTab"  v-bind:value="'active-tab-2'"
                    id="radio-b" type="radio" name="first-switch"/>
            <label for="radio-b">Yearly plan</label><span class="toggle-outside"><span class="toggle-inside"></span></span>
          </div>
        </div>
        <p class="p">Pay for the whole year and save up <br>
          to 10% from your plan</p>

        <div class="pricing-tab-1 monthly" :class="isActiveTab">
          <div class="row">
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Standard</p>
                <h3 class="h3">$0<span>/ per month</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>0.5% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>4% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Standard Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Premium</p>
                <h3 class="h3">$8<span>/ per month</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>1% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>9% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Premium Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Elite</p>
                <h3 class="h3">$28<span>/ per month</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>2% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>14% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Elite Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card active">
                <p>Merchant</p>
                <h3 class="h3">$5<span>/ per month</span></h3>
                <ul>
                  <li>
                    <p>PROCESSING FEE INCOMING</p>
                    <h3>1.2% <span>PER TRANSACTION</span></h3>
                  </li>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>0.7% <span>PER TRANSACTION PROCESSING</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style bigger">Get Merchant Plan</a>
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-tab-2 yearly" :class="isActiveTab">
          <div class="row">
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Standard</p>
                <h3 class="h3">$0<span>/ per year</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>0.5% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>4% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Standard Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Premium</p>
                <h3 class="h3">$88<span>/ per year</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>1% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>9% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Premium Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card">
                <p>Elite</p>
                <h3 class="h3">$302<span>/ per year</span></h3>
                <ul>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>2% <span>Card Top Up</span></h3>
                  </li>
                  <li>
                    <p>STAKING**</p>
                    <h3>14% <span>YEARLY</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style">Get Elite Plan</a>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="pricing-card active">
                <p>Merchant</p>
                <h3 class="h3">$54<span>/ per year</span></h3>
                <ul>
                  <li>
                    <p>PROCESSING FEE INCOMING</p>
                    <h3>1.2% <span>PER TRANSACTION</span></h3>
                  </li>
                  <li>
                    <p>TOKEN BACK*</p>
                    <h3>0.7% <span>PER TRANSACTION PROCESSING</span></h3>
                  </li>
                </ul>
                <a href="https://app.tokash.io/auth/sign-up" class="btn-style bigger">Get Merchant Plan</a>
              </div>
            </div>
          </div>
        </div>

        <ul class="counting">
          <li><p>Token back to your account every time you top up your card with crypto.</p></li>
<!--          <li><p>SWAPPING. By swapping you exchange a cryptocurrency for another without leaving their blockchain.</p></li>-->
          <li><p>STAKING. Crypto staking is the process of locking up crypto holdings in order to obtain rewards or earn interest.</p></li>
        </ul>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
  },
  data() {
    return {
      isActiveTab: 'active-tab-1',
      faqItems: [
        {
          title: 'What is a Tokash Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'What do you get when you become a Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Who can become a Brand Ambassador?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'How do I get paid for my sales?',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
