<template>
    <div class="home bluebg terms">
      <Header/>
             <Banner secondaryBanner="active" buttonLink="" primaryBannerTag="Terms of Service" primaryBannerTitle="Terms" id="product-banner"
             secondaryBannerImg="tokash-gears"/>
  
      
              <section class="section description-about tertiary">
                <div class="container">

                    <!--Header Section-->

                    <!--Content Section-->

                    <div class="description-about-content">
                        <div class="row">
                            <div class="col-content-outer">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="content-wrapper primary">
                                            <div class="content-text">
                                                <div class="desc">
                                                    <p>
                                                        <b>Tokash General Terms of Use</b><br>
                                                        <b>1. Welcome</b><br>
                                                        Welcome to the Tokash General Terms of Use. These terms, combined with our Fees Page, Issuer Terms (where applicable), and any other documents referred to in these terms (collectively, the “Terms”) set out the legal agreement between:
                                                        You, as the Tokash user; and<br>
                                                        The Tokash company identified in paragraph 3 below (or “we”, “our”, or “us”).<br>
                                                        Please read through this document carefully to understand how your account and our services work. If you do not agree to these Terms, do not sign up for or use our services. Users in certain regions may have different Terms applicable to them.<br>
                                                        <br><b>2. Our Service</b><br>
                                                        Tokash operates a platform accessible through the Tokash Website and application, which is available on your device (the “App”), and our website https://tokash.io  that allows you to buy, sell and manage supported currencies and digital payment tokens (“DPTs”), as well as to apply for, manage and load money onto a Tokash payment card (the “Tokash Service”).<br>
                                                        The information provided through the Tokash Service is not intended to be and does not constitute financial advice or any other advice. It is general in nature and not specific to you. It is not an offer to buy or sell or a solicitation of an offer to buy or sell any security, product, service or investment, nor is it a recommendation, endorsement or sponsorship of any security, company or fund.<br>
                                                        You should assess carefully whether your financial situation and tolerance for risk is suitable for any transactions you undertake using the Tokash Service, and you are responsible for conducting your own research before carrying out any transactions through the Tokash.<br>
                                                        <br><b>3. Who We Are</b><br>
                                                        Tokash provides Custody Services, Cards, Staking, Reward Program, Asset Management and Liquidity.<br>

                                                        <br><b>4. Our Licensing</b><br>
                                                        TokashPay Ltd. is a registered MSB with FINTRAC Canada for foreign exchange dealing and money transferring with an Incorporation Number: BC1314385 and address 5307 Victoria Drive #676, Vancouver, British Columbia, V5P 3V6, Canada.<br>
                                                        Tokash UAB is a registered as Virtual Currency Exchange Operator and Deposit Virtual Currency Wallet Operator with the Financial crime investigation service as Authority, under Law on the Prevention of Money Laundering and Terrorist Financing of the Republic of Lithuania with a company code: 305916911 and address Laisvės pr. 60, Vilnius 05103, Lithuania.<br>
                                                        Tokash  KB is a registered as a Professional Trustee in accordance with the Money Laundering and Counter-Terrorism Financing Prevention Act 2017:630 (AML/CTF Compliance) with Stockholm County Administrative Board as Supervisory Authority for AML/CTF purposes with a reg. number 969794-0097 and address Stockholms Brevboxar 1679, 116 74 Stockholm.<br>
                                                        <br><b>5. Your Tokash Profile</b><br>
                                                        In order to use the Tokash Service, you will need to register using your email, set up an account with a password and verify your identity (your “Tokash Profile”).<br>
                                                        We recommend that you use a strong password, with a combination of letters, numbers and special characters, with your Tokash Profile. You must enable 2-factor authentication to access your Tokash Profile.<br>
                                                        Please refer to the security section below (in paragraph 9) for more information about the steps you must take to protect your Tokash Profile.<br>
                                                        <br><b>6. Your Tokash Card</b><br>
                                                        You can order a Tokash payment card (the “Tokash Card”) to use in combination with your Tokash Multicurrency Account. If you have ordered a Tokash Card, you’ll see a separate card account on your Tokash Profile which will show you the balance of your Tokash Card.<br>
                                                        You can top up your Tokash Card from your Tokash Multicurrency Account using the applicable functionality on the Tokash Service. You can then spend the funds on your Tokash Card anywhere where VISA is accepted. A maintenance fee applies to your use of the Tokash Card as set out at, which we will deduct from the funds on your Tokash Multicurrency Account.<br>
                                                        Your Tokash Card is issued by WallexPAY s.r.o. Your use of the Tokash Card is subject to additional terms and conditions that set out the agreement between you and WallexPAY  s.r.o. (the “Issuer Terms”). If you decide to order and use a Tokash Card, you must also read and accept the Issuer Terms.<br>
                                                        <br><b>7. Security</b><br>
                                                        It is extremely important for you to keep your Tokash Profile secure at all times. You are responsible for all transactions initiated through your Tokash Profile. Unauthorized use of your Tokash Profile may have serious consequences for you, including financial consequences. Ultimately, it is your responsibility to keep your Tokash Profile secure by engaging in the following practices:<br>
                                                        Using a strong password for your Tokash Profile;<br>
                                                        Keeping your phone safe and ensuring it is password-protected at all times;<br>
                                                        Enabling 2-factor authentication to access your Tokash Profile;<br>
                                                        Keeping your Tokash Card pin and other unique numbers safe and secure;<br>
                                                        Closing the App when you are not using it;<br>
                                                        Logging out of the Tokash website when you are not using it;<br>
                                                        Changing your mobile, card pin and Tokash Multicurrency Account passwords regularly and not sharing these with anyone; and<br>
                                                        Keeping your email account secure at all time.<br>
                                                        If you believe your mobile, Tokash Card, or the login details for your Tokash Profile have been lost, stolen, or accessed without authorization, you must contact us immediately at support@tokash.io or contacting the Help Center in the app. We will not be responsible for losses incurred due to unauthorized access of your email.<br>
                                                        <br><b>8. Information about You</b><br>
                                                        Keeping your personal data safe is important to us. How we handle your personal data is explained in our privacy policy.<br>
                                                        <br><b>9. Eligibility</b><br>
                                                        You must meet the following criteria in order to create a Tokash Profile:<br>
                                                        You must be 18 years of age or older and be resident of a country where Tokash is available.<br>
                                                        You must be an individual and not acting on behalf of a business. If you wish to open a business account with us please click on the button Business when you sign up.<br>
                                                        You must only open one account with us and not have had a previous account with us that was blocked or closed;<br>
                                                        You must verify your identity with us and pass any other checks we are required to conduct (such as anti-money laundering checks and know your customer procedures).<br>
                                                        <br><b>10. AML/KYC Verification</b><br>
                                                        We take our anti-money laundering (AML) and know your customer (KYC) policies very seriously, and we monitor our platform for suspicious activity. You must comply with all requests to verify your identity, address, and source of funds.<br>
                                                        We will require users to provide valid government-issued proof of identity documents and may ask for proof of address documents issued in the last six months.<br>
                                                        Where we cannot verify your identity or the validity of your documents, you will be refused a Tokash Profile. Where you appear on any governmental or inter-governmental sanctions list, you will be refused a Tokash Profile.<br>
                                                        <br><b>11. Restrictions on using Tokash</b><br>
                                                        You must not use the Tokash Service for any of the following:<br>
                                                        For any illegal purpose, including fraud and money laundering, and for any purpose prohibited by regulation, statute, ordinance, or other governmental or regulatory limitation;<br>
                                                        For any debt-collection purpose;<br>
                                                        To circumvent merchant restrictions or obtain goods or services without paying;<br>
                                                        For commercial speculative trading purposes;<br>
                                                        To manipulate the price of any asset or currency;<br>
                                                        In any manner likely to result in complaints, reversals, chargebacks, fees, fines, penalties, or other liability to Tokash, other users, third parties, or yourself;<br>
                                                        To provide yourself with a cash advance from your credit card, or help others to do so;<br>
                                                        In any manner which results in a negative balance in any currency;<br>
                                                        To burden or overload our infrastructure, facilitate any viruses, malware, malicious code or other form of IT attack or attempt to gain access to our systems and information;<br>
                                                        To interfere with the Tokash Service in any manner;<br>
                                                        To copy or reproduce our content or services in any manner;<br>
                                                        To interfere with our third party providers in any manner;<br>
                                                        To test credit card behaviours;<br>
                                                        To circumvent our policies and procedures;<br>
                                                        For use with any automatic trading, API, crawler scripts, or other methods inconsistent with ordinary use of the Tokash Service;<br>
                                                        For the purpose of crowdfunding, or receiving the proceeds of a crowdfunding campaign;<br>
                                                        To harass our employees, agents, or other users; and<br>
                                                        To trade FX for speculative purposes or FX arbitrage.<br>
                                                        <br><b>12. Tokash Remedies</b><br>
                                                        If you breach these Terms in any manner we may, in our sole discretion, take the following actions:<br>
                                                        Close, suspend, or limit your access to your Tokash Profile, your Tokash Multicurrency Account or your Tokash Card;<br>
                                                        Contact and/or warn third parties in connection with your actions, including other users, your bank or card issuer, law enforcement or regulatory bodies;<br>
                                                        Refuse to complete transactions;<br>
                                                        Fully or partially reverse a transaction or exchange; and<br>
                                                        Pursue legal action against you.<br>
                                                        <br><b>13. Account Closure</b><br>
                                                        You may close your Tokash Profile and Tokash Multicurrency Account at any time with us for any reason by contacting us. However, you may not close your Tokash Profile or Tokash Multicurrency Account while Tokash is conducting an investigation into your use of the Tokash Service, or while you have a remaining balance on your Tokash Multicurrency Account. Even if your Tokash Profile is closed, you will remain liable for any actions taken through your Tokash Profile prior to closure.<br>
                                                        Nothing in this paragraph 15 affects any legal rights you may have under the law of the country in which you are resident<br>
                                                        <br><b>14. Account Suspension and Termination by Tokash</b>
                                                        We may end or suspend your use of the Tokash Service without notice to you, at any time, and with immediate effect in the following circumstances:<br>
                                                        Where you have provided us with false or misleading information;<br>
                                                        Where we determine, in our sole discretion, that your use of your Tokash Profile, Tokash Multicurrency Account is, or is potentially, detrimental or harmful to Tokash in any manner;<br>
                                                        Where you have breached these Terms or engaged in any restricted activity;<br>
                                                        Where we suspect you have breached these Terms or otherwise acted fraudulently, including during the AML/KYC verification process;<br>
                                                        Where we are required to do so to comply with a court order, law, regulation, regulatory decree or ombudsman’s orders;<br>
                                                        Where you have been declared bankrupt; and<br>
                                                        Where you have been declared deceased.<br>
                                                        We exclude all liability for any losses incurred where we have suspended an account in accordance with this paragraph 16. Tokash is not required and may be prohibited by law to provide any further information regarding your account closure or suspension.<br>
                                                        We may also terminate your Tokash Profile and your use of the Tokash Service at any time by giving you 2 months’ notice.<br>
                                                        <br><b>15. Notice and Communications</b><br>
                                                        We’ll get in touch with you through the Tokash Service or the email address you provide on your Tokash Profile. Please make sure to keep this updated with an email address you check regularly. You should also check your transaction history regularly and let us know of any errors or unauthorized transactions. Any communications will be made in English.<br>
                                                        <br><b>16. Customer Support</b><br>
                                                        If you need any help or want to make a comment or complaint, please contact our customer service at support@tokash.io. We aim to resolve all enquiries within two weeks.<br>
                                                        Copies of these Terms are available upon request and on our Website.<br>
                                                        <br><b>17. Unresolved Complaints</b><br>
                                                        Please give us the opportunity to resolve your complaint. We will respond to any complaints within 15 business days of receiving your complaint, except in exceptional circumstances beyond our control. In any event, we will respond to your complaint within 35 business days of receiving your compliant.<br>
                                                        If we have not been able to resolve your complaint to your satisfaction, you may take your complaint to the relevant institution:<br>
                                                        <br><b>18. Intellectual Property</b><br>
                                                        All intellectual property rights in the Tokash Service, and all content and logos are owned by or licensed to Tokash . You may not copy, imitate, or use any of this intellectual property without our prior written consent. Nothing in these Terms grants you any intellectual property rights in the Tokash Service, other than the right to use Tokash Service, and to download our mobile application on your device in order to access the Tokash Service, in accordance with these Terms.<br>
                                                        Your right to use the Tokash Service is personal to you and you are not allowed to give this right to any other person. Your right to use the Tokash Service does not stop us from giving other people the right to use the Tokash Service.<br>
                                                        <br><b>19. Amendments to These Terms</b><br>
                                                        We may make changes to our Terms and policies from time to time. Where possible, we will notify you at least 2 months in advance of the date of these changes. If you do not agree to the changes, you must stop using our services before the planned commencement date of the new changes. Continuing to use the Tokash Service means that you accept our new changes.<br>
                                                        Certain changes may occur immediately and without notice. This may happen when we are required to make changes by law, or where we make minor changes to these Terms that do not impact your rights and remedies or our obligations. When such changes occur, we will notify you as soon as reasonably practicable.<br>
                                                        <br><b>20. Our responsibility to you</b><br>
                                                        The Tokash Service allows you to purchase services and products, and provides you with content and information that are owned or developed by third parties, or that operate on or are supported by third party networks. As we do not have any control over these services or products that you purchase, or content you view using the Tokash Service, we are not responsible for them in any way.<br>
                                                        While we do our best to ensure that the features and functionalities of the Tokash Service are of a reasonably satisfactory standard and are available to you all of the time, certain features may rely on networks and connections that are beyond our control. Due to the nature of the Internet and technology, the Tokash Service is therefore provided on an “as is” and “as available” basis. As such, we cannot guarantee that the Tokash Service won’t be interrupted, or that you will not experience delays, failures or errors when using the Tokash Service.<br>
                                                        We also give no guarantee as to the fitness for purpose of the Tokash Service for your specific needs. To the extent we are able to do so, we exclude any commitments that may be implied by law. For any claim, our responsibility to you will be limited to any amounts you have paid us in the 12 months preceding your claim. If you have not paid us anything, we shall not be responsible to you for any claim arising out of the provision of the Tokash Service.<br>
                                                        In every case, we will never be responsible for any loss or damage that is not reasonably foreseeable, or that is caused by a failure by you to comply with these Terms.<br>
                                                        <br><b>21. Our Right to Set-Off</b><br>
                                                        Sometimes we might be entitled to be compensated by you, for instance due to a technical error in your favour, when you have a negative balance on your account, or when you use the Tokash Service in such a way that violates the Terms. In such cases, we shall be entitled to recover any sum due to us by retaining some or all of your available funds or balances that you have stored in your Tokash Multicurrency Account or DPTs you have bought through the Tokash Service, regardless of what currency or DPTs those balances are held in.<br>
                                                        Where necessary, we will convert currencies and DPTs at the applicable exchange rate.<br>
                                                        <br><b>22. Your responsibilities</b>
                                                        You are responsible for:<br>
                                                        All charges and other amounts incurred through your use of the Tokash Service at any time, including any amounts outstanding after you stop using Tokash or if we suspend or cancel your Tokash Profile; and<br>
                                                        Complying with any and all laws, rules and regulations of your jurisdiction that may apply to you in connection with your use of the Tokash Service, including but not limited to activities of import and export, taxes or foreign currency transactions. We will not be responsible for the execution of tax obligations, or calculation and transfer of taxes applied to you.<br>
                                                        You are also responsible for keeping a copy of any information you upload to the Tokash Service or that is accessible through your Tokash Profile. We will not offer you compensation for any losses you might suffer as a result of any information that you access on the Tokash Service being deleted, or your access to such information being terminated or suspended if you or we terminate or suspend your use of the Tokash Service.<br>
                                                        <br><b>23. Apple App Store Provisions</b><br>
                                                        This paragraph 25 applies where the App has been acquired from the Apple App Store. You acknowledge and agree that the Terms are solely between you and Tokash, not Apple, Inc. (“Apple”) and that Apple has no responsibility for the App or content thereof. Your use of the App must comply with the App Store Terms of Service.<br>
                                                        You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App. In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and (where applicable) Apple will refund the purchase price for the App to you; to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be solely governed by the Terms and any law applicable to Tokash’s provider of the App.<br>
                                                        You acknowledge that Apple is not responsible for addressing any claims of you or any third party relating to the App or your possession and/or use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation; and all such claims are governed solely by the Terms and any law applicable to Tokash’s provider of the software.<br>
                                                        You acknowledge that, in the event of any third-party claim that the App or your possession and use of that App infringes that third party’s intellectual property rights, Tokash, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Terms.<br>
                                                        You represent and warrant that (i) you are not located in a country that is subject to U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.<br>
                                                        You and Tokash acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of the Terms of Service as relates to your license of the App, and that, upon your acceptance of the terms and conditions of the Terms of Service, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service as relates to your license of the App against you as a third-party beneficiary thereof.<br>
                                                        <br><b>24. Downloading Our App from other App Stores</b><br>
                                                        Where you download our App from any other app store or distribution platform other than the Apple App Store, including the Google Play Store (the “Distribution Platform”) you agree that: (a) the Terms are between you and Tokash, and not with the provider of the Distribution Platform (“Store Provider”); (b) your use of the App must comply with the Store Provider’s then-current Distribution Platform Terms of Service; (c) the Store Provider is only a provider of the Distribution Platform where you obtained the App; (d) Tokash, and not the Store Provider, is solely responsible for the App;&lt; (e) the Store Provider has no obligation or liability to you with respect to the App or the Terms; and (f) you acknowledge and agree that the Store Provider is a third-party beneficiary to the Terms as it relates to the App<br>
                                                        <br><b>25. Accessing a Deceased’s Account</b><br>
                                                        In the event that you need to access a deceased’s Tokash Profile, please contact us. We may ask you for supporting documentation, such as a death certificate, probate documents, or other documents proving you have the right to administer the deceased’s estate.<br>
                                                        <br><b>26. No Investment Advice</b><br>
                                                        We do not provide any type of investment advice. We may provide information concerning types of currencies and DPTs, prices, and events that may have influenced prices, all of which should not be considered investment advice. If you require investment advice you should contact a financial advisor. You are solely responsible for how you use our services and the financial results of your actions.<br>
                                                        <br><b>27. Governing Law and Dispute Resolution</b><br>
                                                        The applicable Jurisdiction  will be the one where the customer resides. The jurisdiction will apply to all disputes and the interpretation of these Terms. The  court will have non-exclusive jurisdiction over any dispute arising from or related to your use of the Tokash Service. This does not affect your rights under the law of the country in which you are resident, including (where applicable) your right to have a dispute in relation to your use of the Tokash Service heard in the court of that country.<br>
                                                        <br><b>28. Miscellaneous</b><br>
                                                        No third-party rights are created in these Terms. Only you, as the holder of the Tokash Profile will have any rights to enforce these Terms. You cannot assign or transfer any of your rights under these Terms to someone else.<br>
                                                        Where we do not enforce our rights under these Terms, we do not waive our rights. We may transfer or assign our rights under these Terms at any time.<br>
                                                        Unless stated otherwise in these Terms, if any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the extent possible.<br>
                                                        Prohibited Articles and Transactions means: (1) drug paraphernalia or narcotics, steroids, certain controlled substances or other products that present a risk to consumer safety, (2) stolen goods including digital and virtual goods or which encourage, promote, facilitate or instruct others to engage in illegal activity (3) the promotion of hate, violence, racial intolerance or the financial exploitation of a crime, (4) items that are considered obscene, (5) items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under Applicable Laws, (6) ammunition, firearms, certain firearm parts or accessories, or certain weapons or knives regulated or prohibited under Applicable Laws; (7) are associated with purchases of annuities or lottery contracts, lay-away systems, offshore banking or transactions to finance or refinance debts funded by a credit card, (8) are for the sale of certain items before the seller has control or possession of the item, (9) are by payment processors to collect payments on behalf of merchants, (10) are associated with the sale of traveller’s checks, money orders, money transfers, MoneySend Intracountry, MoneySend Intercountry or MoneySend Funding, (11) involve certain credit or debt settlement services, credit transactions or insurance activities, (12) involve offering or receiving payments for the purpose of bribery or corruption; (13) involve gambling activities; (14) any automated fuel dispenser transactions (15) are associated with direct marketing services, (16) massage parlours, (17) timeshares; or (18) chemicals and allied products not elsewhere classified.<br>
                                                        Prohibited Countries means any of the following: Belarus, Burma (Myanmar), Burundi, Central African Republic, Congo Democratic Republic, Crimea region of Ukraine ,Cuba, Iran Lebanon, Libya, North Korea, Russia, Ukraine, Somalia, South Sudan, Syria, Venezuela, Yemen, Zimbabwe or any other country which (or with any other persons who) is sanctioned by the United Nations Security Council, or under applicable laws of your country of residence.<br>


                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


      <Footer/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Header from '../components/Header';
  import Footer from '../components/Footer';
  import Banner from '../components/Banner';
  
  export default {
    name: 'Home',
    components: {
      Header,
      Footer,
      Banner
    },
    data() {
      return {
        activeTab: '1',
      }
    },
    methods: {}
  }
  </script>
  