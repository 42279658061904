<template>
  <div class="home">
    <Header/>

    <Banner primaryBanner="active" buttonLink="affiliate" primaryBannerTag="Careers at Tokash" primaryBannerTitle="Unlock the Best of Yourself and <br/> Become Free"
            primaryBannerDescription="Join a world class team"/>

    <section id="benefits">
      <div class="container text-center">
        <h2 class="left">Our Benefits</h2>
        <div class="row row-eq-height">
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-1.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
               <p class="p">Competitive salary</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-2.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Be part of the financial evolution</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-3.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Travelling options</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-4.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Bonus options</p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-5.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Comprehensive health plan</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-6.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Training and development</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-7.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Community Event</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4">
            <div class="benefits-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/career-benefit-card-icon-8.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <p class="p">Health and wellness perks</p>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="btn-style arrow">View All Jobs</a>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
