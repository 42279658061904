<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" :secondaryBannerBtn="true"   primaryBannerTitle="Franchise <br/> Opportunities <br/> Are Available <br/> Globally!"
           secondaryBannerImg="franchising-hero"/>


    <section class="what-tokash-provide what-we-provide">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 align-self-center">
            <div class="content-wrapper">
              <h2>What We Provide:</h2>
              <ul class="counting small">
                <li><p class="p"><b>Recognized and reputable</b> brand identity of Tokash. </p></li>
                <li>
                  <p class="p"><b>Access to Tokash services</b> - a fully regulated and licensed'
                    for foreign exchange dealing, money transferring and dealing in virtual currencies</p>
                </li>
                <li><p class="p">Sharing of <b>revenue</b> generated from the users in your region.</p></li>
                <li><p class="p"><b>Turnkey ready to start solution,</b> which includes Tokash’s team handling KYC, providing customer support, performing financial services, and offering client confidentiality.</p></li>
                <li><p class="p">Use of a <b>fast and secure</b> digital stablecoin platform.</p></li>
                <li><p class="p">Access to an <b>extensive portfolio</b> of coins (100 + digital assets)</p></li>
                <li><p class="p"><b>Marketing Support</b> and established branding.</p></li>
                <li><p class="p"><b>Deep liquidity pool</b> connected to the native exchange via API.</p></li>
                <li><p class="p">An opportunity to utilize Tokash’s <b>existing network of business partners</b> (Banks, payment providers, and more).</p></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/what-we-provde.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="what-tokash-provide what-you-provide">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 order-2 order-lg-1 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/what-you-provide.png" alt="">
            </div>
          </div>
          <div class="col-lg-6 col-md-12 order-1 order-lg-2 align-self-center">
            <div class="content-wrapper">
              <h2>What You Provide:</h2>
              <ul class="counting small">
                <li><p class="p">Invest in the franchise and settle monthly marketing <b>& business development expenses</b> in your region.</p></li>
                <li><p class="p">Cultivate and maximize influence in your local community while <b>driving the adoption of cryptocurrencies</b> worldwide.</p></li>
                <li><p class="p">Perform and maintain <b>strong marketing,</b> including the use of social media in your region.</p></li>
                <li><p class="p">Organize <b>flawless operation</b> of the franchise in compliance with the Tokash’s policies & procedures.</p></li>
                <li><p class="p">Coordinate and promote Tokash through the <b>local events</b> in your region.</p></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="franchise-candidates">
      <div class="container">
        <h2>An ideal franchise candidate</h2>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="franchise-candidates-card">
              <div class="img-wrapper">
                <img src="../assets/images/franchise-candidates-img-1.png"   alt="">
              </div>
              <div class="content-wrapper">
                <p class="p">An active Crypto expert well connected in the local community</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="franchise-candidates-card">
              <div class="img-wrapper">
                <img src="../assets/images/franchise-candidates-img-2.png"   alt="">
              </div>
              <div class="content-wrapper">
                <p class="p">Good knowledge of the <br>cryptocurrency market.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="franchise-candidates-card">
              <div class="img-wrapper">
                <img src="../assets/images/franchise-candidates-img-3.png"   alt="">
              </div>
              <div class="content-wrapper">
                <p class="p">Experienced in managing a business(s) and have a keen interest in investing in new business ventures.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="currencies-slider" class="tokash-gallery">
      <div class="container">
        <h2 class="left">Gallery of Tokash Shop</h2>
      </div>
      <carousel :margin="32" :items="2" :stagePadding="300" :autoplay="true"
                :responsive="{0:{items:1,nav:false, loop:true, stagePadding:40, margin:16 },
                768:{items:2, stagePadding:40,nav:true, loop:true },
                1600:{items:2, stagePadding:300,nav:true, loop:true }}">
        <div class="item">
          <div class="gallery-card">
            <img src="../assets/images/img1.jpg" alt="How work">
          </div>
        </div>
        <div class="item">
          <div class="gallery-card">
            <img src="../assets/images/img2.jpg" alt="How work">
          </div>
        </div>
        <div class="item">
          <div class="gallery-card">
            <img src="../assets/images/img3.jpg" alt="How work">
          </div>
        </div>
        <div class="item">
          <div class="gallery-card">
            <img src="../assets/images/img4.jpg" alt="How work">
          </div>
        </div>
        <div class="item">
          <div class="gallery-card">
            <img src="../assets/images/img5.jpg" alt="How work">
          </div>
        </div>
      </carousel>
    </section>

    <section id="become-franchise">
      <div class="container">
        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/become-franchise-img.png" alt="">
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h2>Become a Franchise</h2>
              <p class="p">Please provide further details about yourself and your
                company. We're looking for experienced partners with strong business
                acumen and serious intentions.</p>
              <p class="small">For any other queries, please contact our Franchise Expert</p>
              <a href="https://k9654f4dt2i.typeform.com/Franchise" class="btn-style primary-arrow">Apply Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import carousel from 'vue-owl-carousel';


export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    carousel
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>
