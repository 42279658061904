<template>
  <div class="home affiliate bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="ambassador" :playStore="true" primaryBannerTitle="Become a Tokash Affiliate"
            primaryBannerDescription="" secondaryBannerImg="affiliate"/>

    <section id="affiliate-perks">
      <div class="container">
        <div class="affiliate-perks-wrapper d-none d-md-block">
          <div class="row">
            <div class="col-md-6 align-self-center">
              <div class="content-wrapper">
                <h2>AFFILIATE PERKS</h2>
                <p class="p">Join our affiliate program and earn tokens  by <br>
                  promoting Tokash</p>
                <div class="earn-perk">
                  <strong>EARN 10 EURST</strong>
                  <p>When you invite a new customer to Tokash, you can earn 10 EURST</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 align-self-center">
              <div class="img-wrapper">
                <img src="../assets/images/affiliate-perk-img.svg" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="affiliate-perks-wrapper-mobile d-md-none d-block">
          <h2>AFFILIATE PERKS</h2>
          <p>When you invite a new customer to Tokash, you can earn 10 EURST</p>
          <a href="#" class="btn-style primary-arrow">Get Started</a>
        </div>
      </div>
    </section>

    <section id="how-affiliate-work">
        <div class="container">
          <h2 class="left">HOW IT WORKS</h2>
          <p class="p">Follow these 3 easy steps to join the <br>
            community of Tokash Affiliates</p>
          <a href="#" class="btn-style">Become An Affiliate</a>
          <div class="row">
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>1.</strong>
                </div>
                <div class="step-content">
                  <h3>Create and verify your account in Tokash</h3>
                <p class="p">Learn how to become a Tokash Brand Ambassador. Click "Get Started" to apply.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>2.</strong>
                </div>
                <div class="step-content">
                  <h3>Invite your friends to register on Tokash</h3>
                <p class="p">Fill out a super-simple Brand Ambassador questionnaire so we know more about you.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center">
              <div class="steps-card">
                <div class="step-count">
                  <strong>3.</strong>
                </div>
                <div class="step-content">
                  <h3>Earn EURST</h3>
                <p class="p">After they complete the verification process and deposit 100 EUR you will earn your EURST.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    <section id="faq">
      <div class="container">
        <h2 class="left">Frequently Asked Question</h2>
        <VueFaqAccordion
            :items="faqItems"
        />
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    VueFaqAccordion
  },
  data() {
    return {
      faqItems: [
        {
          title: 'What is a Tokash Affiliate Program?',
          value: 'The Tokash Affiliate Program allows you to create a unique referral code through which you can invite your community to register on the Tokash platform. For any client using the referral code during the registration, you will have a chance to earn 10 EURST. ',
          category: 'Tab-1'
        },
        {
          title: 'Who can become an affiliate?',
          value: 'Anyone who is above the age of 18 and applies to the affiliate program. Just fill out the contact form. The form will be reviewed and evaluated according to the criteria and if they are met you will be approved. A member of our team will be in touch with you for further information. ',
          category: 'Tab-1'
        },
        {
          title: 'What do you get when you become an affiliate?',
          value: ' You have a chance to earn 10 EURST in your Tokash account for each referral if the following requirements are fulfilled by your friends:'+ "<br/>"+
                  '- The referral code is used during the registration'+ "<br/>"+
                  '- The KYC process is completed and the profile is fully verified'+ "<br/>"+
                  '- Minimum 100 EUR are deposited in the account'+ "<br/>"+
                  'After these you will receive the EURST in your Tokash account. ',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
