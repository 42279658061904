<template>
    <div class="home bluebg currencies">
      <Header/>
  
      <Banner secondaryBanner="active" :secondaryBannerBtn="true"   primaryBannerTitle="Supported Currencies"
             secondaryBannerImg="currencies"/>
  

        
        <section id="coins cryptomarkets">
            <div class="container">
                <center>
                    <a href="https://app.tokash.io/auth/sign-in">
                        <button class="btn-style">
                            <span class="font-semibold text-base font-Inter text-white">Buy now</span>
                        </button>
                    </a>
                </center>
                <!--<h3 class="text-center">Cryptocurrencies</h3>-->
                <div class="cryptoTable table responsive">
                    <table width="100%" style="text-align: left;">
                        <tr>
                            <th>Name</th>
                            <th></th>
                            <th>Network</th>
                            <th>Category</th>
                            <th>Market Cap (USD)</th>
                            <th>Price (USD)</th>
                            <th>Change (24h)</th>
                            <th>Staking</th>
                            <th></th>
                        </tr>
                        <tbody>
                            <tr>
                                <td><img src="/coins/eurst.png"> EURST</td>
                                <td>EURST</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>Nan</td>
                                <td id="eurstPrice">$0.972823</td>
                                <td>Nan</td>
                                <td>14%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/usd-coin.png"> USD Coin</td>
                                <td>USDC</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$44.58 Bln</td>
                                <td>$1.001</td>
                                <td>+0.04%</td>
                                
                                <td>9%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/tether.png"> Tether</td>
                                <td>USDT</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$66.21 Bln</td>
                                <td>$0.999984</td>
                                <td>-0.02%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/dai.png">DAI</td>
                                <TD>DAI</TD>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$5.16 Bln</td>
                                <td>$0.999961</td>
                                <td>+0.18%</td>
                                
                                <td>9%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/usdd.png">USDD</td>
                                <td>USDD</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$711.01 Mln</td>
                                <td>$0.980842</td>
                                <td>+0.01%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/feiusd.png">Fei</td>
                                <td>FEI</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$45.70 Mln</td>
                                <td>$0.996039</td>
                                <td>+1.82%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/gusd.png">Gemini Dollar</td>
                                <td>GUSD</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$589.14 Mln</td>
                                <td>$0.99931</td>
                                <td>+0.10%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/tusd.png">True USD</td>
                                <td>TUSD</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$754.29 Mln</td>
                                <td>$0.999709</td>
                                <td>+0.12%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/pax.png">Pax Dollar</td>
                                <td>USDP</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$3.02 Mln</td>
                                <td>$0.989026</td>
                                <td>-0.29%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/busd.png">Binance USD</td>
                                <td>BUSD</td>
                                <td>ERC-20</td>
                                <td>Stablecoin</td>
                                <td>$18.34 Bln</td>
                                <td>$1</td>
                                <td>+0.03%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/bitcoin.svg"> Bitcoin</td>
                                <td>BTC</td>
                                <td>BTC</td>
                                <td>Crypto</td>
                                <td id="bitcoinMkc">322.85 Bln</td>
                                <td id="bitcoinPrice">$16787.15</td>
                                <td id="bitcoinVol">+0.15%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/ethereum.svg"> Ethereum</td>
                                <td>ETH</td>
                                <td>Ethereum</td>
                                <td>Crypto</td>
                                <td id="ethereumMkc">145.68 Bln</td>
                                <td id="ethereumPrice">$1208.78</td>
                                <td id="ethereumVol">+1.87%</td>
                                
                                <td>6.5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/bitcoin-cash.png"> Bitcoin cash</td>
                                <td>BCH</td>
                                <td>BCH - CashAddr</td>
                                <td>Crypto</td>
                                <td id="bchMkc">1.94 Bln</td>
                                <td id="bchPrice">$100.71</td>
                                <td id="bchVol">-0.68%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/litecoin2d.png" title=""> Litecoin</td>
                                <td>LTC</td>
                                <td>Litecoin</td>
                                <td>Crypto</td>
                                <td id="litecoinMkc">4.69 Bln</td>
                                <td id="litecoinPrice">$65.24</td>
                                <td id="litecoinVol">2.29%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/dash.png">Dash</td>
                                <td>DASH</td>
                                <td>DASH</td>
                                <td>Crypto</td>
                                <td id="dashMkc">468.76 Mln</td>
                                <td id="dashPrice">$42.42</td>
                                <td id="dashVol">-1.87%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/solana.png">Solana</td>
                                <td>SOL</td>
                                <td>Solana</td>
                                <td>Crypto</td>
                                <td id="solanaMkc">4.53 Bln</td>
                                <td id="solanaPrice">$12.34</td>
                                <td id="solanaVol">-0.04%</td>
                                
                                <td>5%*</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/dot.png">Polkadot</td>
                                <td>DOT</td>
                                <td>Polkadot</td>
                                <td>Crypto</td>
                                <td id="polkadotMkc">5.46 Bln</td>
                                <td id="polkadotPrice">$4.61</td>
                                <td id="polkadotVol">-0.42%</td>
                                
                                <td>NA</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/uniswap.png" title="">Uniswap</td>
                                <td>UNI</td>
                                <td>ERC-20</td>
                                <td>Crypto</td>
                                <td id="uniswapMkc">4.02 Bln</td>
                                <td id="uniswapPrice">$5.34</td>
                                <td id="uniswapVol">-0.12%</td>
                                
                                
                                <td>NA</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/xrp.png">Ripple</td>
                                <td>XRP</td>
                                <td>Ripple</td>
                                <td>Crypto</td>
                                <td id="rippleMkc">17.38 Bln</td>
                                <td id="ripplePrice">$0.345331</td>
                                <td id="rippleVol">0.23%</td>
                                
                                <td>NA</td>
                                
                            </tr>
                            <tr>
                                <td><img src="/coins/ada.png">Cardano</td>
                                <td>ADA</td>
                                <td>Cardano</td>
                                <td>Crypto</td>
                                <td id="cardanoMkc">9.05 Bln</td>
                                <td id="cardanoPrice">$0.258206</td>
                                <td id="cardanoVol">-2.33%</td>
                                
                                <td>NA</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style="margin-bottom: 0px;" id="lastUpdated" class="asterisk"></p>
                <p class="asterisk">*Annual percentages paid on a daily base</p>
            </div>
        </section>  

        <section id="coins">
            <div class="container">
                <h3 class="text-center">Fiat</h3>
                <div class="cryptoTable table responsive">
                    <table width="100%" style="text-align: left;">
                        <tbody>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/USD.svg" /> United States Dollar*</td>
                                <td><img src="https://hexcom.solutions/coins/AUD.svg" /> Australian Dollar**</td>
                                <td><img src="https://hexcom.solutions/coins/BRL.svg" /> Brazilian Real**</td>
                                <td><img src="https://hexcom.solutions/coins/BGN.svg" /> Bulgarian Lev**</td>
                                <td><img src="https://hexcom.solutions/coins/CAD.svg" /> Canadian Dollar**</td>
                            </tr>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/GBP.svg" /> Pound Sterling*</td>
                                <td><img src="https://hexcom.solutions/coins/HKD.svg" /> Hong Kong Dollar**</td>
                                <td><img src="https://hexcom.solutions/coins/INR.svg" /> Indian Rupee**</td>
                                <td><img src="https://hexcom.solutions/coins/MXN.svg" /> Mexican Peso**</td>
                                <td><img src="https://hexcom.solutions/coins/JPY.png" /> Japanese Yen**</td>
                            </tr>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/EUR.svg" /> Euro*</td>
                                <td><img src="https://hexcom.solutions/coins/RON.png" /> Romanan Leu**</td>
                                <td><img src="https://hexcom.solutions/coins/ZAR.png" /> South African rand**</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="asterisk">* Deposit, withdrawal and exchange <br/>** Exchange only</p>
            </div>
        </section> 
        
        <section id="coins">
            <div class="container">
                <h3 class="text-center">Cryptocurrencies</h3>
                <div class="cryptoTable table responsive">
                    <table width="100%" style="text-align: left;">
                        <tbody>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/bitcoin.svg" /> BTC</td>
                                <td><img src="https://hexcom.solutions/coins/ethereum.svg" /> ETH</td>
                                <td><img src="https://hexcom.solutions/coins/tether.png" /> USDT</td>
                                <td><img src="https://hexcom.solutions/coins/usd-coin.png" /> USDC</td>
                                <td><img src="https://hexcom.solutions/coins/eurst.png" /> EURST</td>
                            </tr>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/feiusd.png" /> FEI*</td>
                                <td><img src="https://hexcom.solutions/coins/gusd.png" /> GUSD*</td>
                                <td><img src="https://hexcom.solutions/coins/tusd.png" /> TUSD*</td>
                                <td><img src="https://hexcom.solutions/coins/usdd.png" /> USDD*</td>
                                <td><img src="https://hexcom.solutions/coins/dai.png" /> DAI</td>
                            </tr>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/pax.png" /> PAX*</td>
                                <td><img src="https://hexcom.solutions/coins/bitcoin-cash.png" /> BCH</td>
                                <td><img src="https://hexcom.solutions/coins/litecoin2d.png" /> LTC</td>
                                <td><img src="https://hexcom.solutions/coins/dash.png" /> DASH</td>
                                <td><img src="https://hexcom.solutions/coins/ada.png" /> ADA</td>
                            </tr>
                            <tr>
                                <td><img src="https://hexcom.solutions/coins/dot.png" /> DOT</td>
                                <td><img src="https://hexcom.solutions/coins/uniswap.png" /> UNI</td>
                                <td><img src="https://hexcom.solutions/coins/solana.png" /> SOL</td>
                                <td><img src="https://hexcom.solutions/coins/xrp.png" /> XRP</td>
                                <td><img src="https://hexcom.solutions/coins/busd.png" /> BUSD*</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="asterisk">* No exchange pairs (available only for deposits and withdrawals)</p>
            </div>
        </section> 
  
      <Footer/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Header from '../components/Header';
  import Footer from '../components/Footer';
  import Banner from '../components/Banner';
  
  
  export default {
    name: 'Home',
    components: {
      Header,
      Footer,
      Banner
    },
    data() {
      return {
  
      }
    },
    methods: {}
  }
  </script>
  