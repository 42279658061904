<template>
    <div class="home bluebg terms">
      <Header/>
             <Banner secondaryBanner="active" buttonLink="" primaryBannerTag="Website Content Disclaimer " primaryBannerTitle="Disclaimer" id="product-banner"
             secondaryBannerImg="tokash-gears"/>
  
      
             <section class="section description-about tertiary">
                <div class="container">

                    <!--Header Section-->

                    <!--Content Section-->

                    <div class="description-about-content">
                        <div class="row">
                            <div class="col-content-outer">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="content-wrapper primary">
                                            <div class="content-text">
                                                <div class="desc">
                                                    <p>
                                                        <b>Website Content Disclaimer</b><br>
                                                        Please read this entire disclaimer before using this website.<br>
                                                        The articles, statements, information, opinions and views, including those contained in any external links contained in this website (together as “Material”) are for general, educational and reference purposes only and do not necessarily reflect those of Tokash, its subsidiaries or its affiliates (the “Company”). All Material has been obtained from sources believed to be reliable, but the Company does not make any representation or warranty, express or implied, as to its accuracy, completeness, timeliness or correctness for any particular purpose. All Material is subject to change without notice. Any recommendation contained herein does not have regard to the specific investment objectives, financial situation and the particular needs of any specific addressee.
                                                        <br><br>
                                                        The Material herein does not constitute financial, investment, tax, legal, accounting or other professional advice on any matter, nor does it evaluate, recommend or endorse any advisory firm or investment vehicle.
                                                        <br><br>
                                                        The Material herein is not to be construed as an offer or a solicitation of an offer to enter into any transaction (including buy or sell any securities, futures, options or other financial instruments).
                                                        <br><br>
                                                        The Material herein is published for the information of addressees only and are not to be taken in substitution for the exercise of judgment by addressees.<br>
                                                        The Material has not been authorized and reviewed by any regulatory authority. You are advised to exercise caution in relation to the Material. If you are in any doubt about any of the Material, you should obtain independent financial, investment, tax, legal, accounting or other professional advice. The Company accepts no liability for any direct, special, indirect, consequential, incidental damages or any other loss or damages of any kind arising from any use of the Material herein (including any error, omission or misstatement herein, negligent or otherwise) or further communication thereof, even if the Company or any other person has been advised of the possibility thereof. The Material is not intended for dissemination, and may not be accessed or relied upon by any individuals or entities, in any jurisdiction where such distribution or use would be against applicable laws and regulations. Similarly, it may not be used for offers or solicitations or distributed in any jurisdiction, or in any circumstances in which such offer or solicitation is unlawful or unauthorized, or where there would be, under such distribution, new or additional registration or licensing requirements. Reproduction,
                                                        distribution, republication and retransmission of the whole or any part of the Material is not allowed without the prior consent of the Company. All trademarks, service marks, product names, logos, and brands are the property of their respective owners.
                                                        

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


      <Footer/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Header from '../components/Header';
  import Footer from '../components/Footer';
  import Banner from '../components/Banner';
  
  export default {
    name: 'Home',
    components: {
      Header,
      Footer,
      Banner
    },
    data() {
      return {
        activeTab: '1',
      }
    },
    methods: {}
  }
  </script>
  