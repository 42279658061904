<template>
  <div>
    <section class="banner" v-if="primaryBanner === 'active' ">
      <div class="container text-center">
        <div class="content-wrapper">
          <img src="../assets/images/banner-usd-icon.png" class="banner-ethereum" alt="">
          <img src="../assets/images/banner-eurst-icon.png" class="banner-flow" alt="">
          <img src="../assets/images/banner-wallex-icon.png" class="banner-wallex" alt="">
          <img src="../assets/images/banner-dai-icon.png" class="banner-solana" alt="">
          <img src="../assets/images/banner-tether-icon.png" class="banner-tether" alt="">
          <strong v-html="primaryBannerTag"></strong>
          <h1 v-html="primaryBannerTitle"></h1>
          <p class="p" v-html="primaryBannerDescription"></p>
          <RouterLink :to="buttonLink" class="btn-style">Get Started</RouterLink>
        </div>
      </div>
    </section>

    <section class="banner atm-banner" v-if="atmBanner === 'active' ">
      <div class="container text-center">
        <div class="content-wrapper">
          <img src="../assets/images/atm-banner-coinns-left-image.png" class="atm-banner-coins-left-image" alt="">
          <img src="../assets/images/atm-banner-coinns-right-image2.png" class="atm-banner-coins-right-image" alt="">
          <h1 v-html="primaryBannerTitle"></h1>
          <p class="p" v-html="primaryBannerDescription"></p>
          <img src="../assets/images/atm-banner-coins-mobile-image2.png" class="atm-banner-coins-mobile-image" alt="">
        </div>
      </div>
    </section>

    <section class="banner secondary-banner" :id="id" v-if="secondaryBanner === 'active' ">
      <div class="container text-center">
        <div class="row">
          <div class="col-md-7">
            <div class="content-wrapper">
              <strong v-html="primaryBannerTag" v-if="primaryBannerTag !== '' "></strong>
              <h1 v-html="primaryBannerTitle"></h1>
              <!--<div class="banner-search-form" v-if="bannerForm === true">
                <form>
                  <input type="text" placeholder="Search News" class="form-control">
                  <button type="submit" class="btn-style">Search</button>
                </form>
              </div>-->
              <p class="p" v-html="primaryBannerDescription" v-if="primaryBannerDescription !== '' "></p>
              <a href="https://app.tokash.io/auth/sign-up"  class="btn-style arrow" v-if="secondaryBannerBtn !== true">Get Started</a>
              <ul class="playstore" v-if="playStore === true">
                <li><a href="https://play.google.com/store/apps/details?id=com.tokash"><img src="../assets/images/playstore-img.png" alt=""></a></li>
                <li><a href=""><img src="../assets/images/appstore-img.png" alt=""></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-5">
            <div class="img-wrapper">

            </div>
          </div>
         <div class="img-wrapper">
           <img :src="require(`../assets/images/${secondaryBannerImg}.png`)" class="secondary-banner-img" alt="Img" v-if="secondaryBannerImg !== ''">
         </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'Banner',
  props: ['primaryBanner', 'secondaryBanner', 'atmBanner', 'primaryBannerTag', 'primaryBannerTitle', 'primaryBannerDescription', 'buttonLink', 'secondaryBannerImg',
          'playStore', 'bannerForm', 'id', 'secondaryBannerBtn'],
  components: {
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
