<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" :playStore="false" :bannerForm="true" primaryBannerTitle="Tokash News Room"
            primaryBannerDescription="" secondaryBannerImg="newsroom" id="blog-banner"/>

    <section>
      <div id="articlesNew"></div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner
  }
}
</script>
