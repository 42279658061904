<template>
  <div class="faq">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" :bannerForm="true" :playStore="false" primaryBannerTitle="How can we help you?"
            secondaryBannerImg="faq-banner-image" id="faq-banner"/>

    <section id="faq" class="faq-detail">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="faq-main-wrapper active">
              <h3 class="h3">General Information</h3>
              <p>Information about our innovative Tokash Platform</p>
              <VueFaqAccordion
                  :items="generalInformation"
              />
            </div>
            <div class="faq-main-wrapper">
              <h3 class="h3">Account Security &    Sign in</h3>
              <p>Explore your account’s security features and common login
                questions associated with various 2FA methods.</p>
              <VueFaqAccordion
                  :items="accountSecurity"
              />
            </div>
            <div class="faq-main-wrapper">
              <h3 class="h3">Financial Operations</h3>
              <p>Learn more about our Financial operations, our trading
                interfaces, supported cryptos, and how to execute transfers
                and exchanges</p>
              <VueFaqAccordion
                  :items="financialOperation"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="faq-main-wrapper">
              <h3 class="h3">Tokash Card Services</h3>
              <p>Detailed Information about our cards, and how to use them</p>
              <VueFaqAccordion
                  :items="cardServices"
              />
            </div>
            <div class="faq-main-wrapper">
              <h3 class="h3">Tokash Education & FAQs</h3>
              <p>Detailed Information about our cards, and how to use them</p>
              <VueFaqAccordion
                  :items="educationFaq"
              />
            </div>
            <div class="faq-main-wrapper">
              <h3 class="h3">Policies, Terms & Compliance Rules</h3>
              <p>Detailed Information about our cards, and how to use them</p>
              <VueFaqAccordion
                  :items="policiesTermsRule"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import VueFaqAccordion from 'vue-faq-accordion';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    VueFaqAccordion
  },
  data() {
    return {
      generalInformation: [
        {
          title: 'Tokash',
          value: 'Benefit of Tokash <br/> What is Tokash? <br> Remittance <br> Pay with stablecoin by card <br> Merchants accepting payment and paying',
          category: 'Tab-1'
        },
        {
          title: 'Policies, Security, and other information about Tokash',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Fees',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
      financialOperation: [
        {
          title: 'FIAT operations',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Cryptocurrency operations',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
      accountSecurity: [
        {
          title: 'Sign-up Process and Account Security',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Common Login Issues',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
      cardServices: [
        {
          title: 'Card Usages',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Common Card Issues',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
      educationFaq: [
        {
          title: 'Platform Usage Guides',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Tokash Affiliate Program',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Partnership and Institutional Inquiry',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Security Audit',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'FAQs',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Brand Ambassador',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'Fraud Monitoring',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
      policiesTermsRule: [
        {
          title: 'Compliance Rules',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
        {
          title: 'AML/CTF and Privacy Policies',
          value: 'Tokash ambassadors help us spread the ideas and services ' +
              'of Tokash by showing their love and passion for our way of working' +
              ' and living! Our ambassadors are sociable individuals who are active ' +
              'in their communities and motivated to make the world a better place.',
          category: 'Tab-1'
        },
      ],
    }
  },
  methods: {}
}
</script>
