var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logo"},[_c('RouterLink',{attrs:{"to":"/"}},[_c('img',{staticStyle:{"max-width":"120px"},attrs:{"src":require("../assets/images/tokash-white.svg"),"alt":""}})])],1),_vm._m(0),_c('div',{staticClass:"row footer-links"},[_c('div',{staticClass:"col-md-3 col-6"},[_c('strong',[_vm._v("PRODUCTS")]),_c('ul',[_c('li',[_c('RouterLink',{attrs:{"to":"/card"}},[_vm._v("Cards")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/staking"}},[_vm._v("Staking")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/product"}},[_vm._v("Products")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/pricing"}},[_vm._v("Pricing")])],1)])]),_c('div',{staticClass:"col-md-3 col-6"},[_c('strong',[_vm._v("ABOUT US")]),_c('ul',{staticClass:"coming-soon"},[_c('li',[_c('RouterLink',{attrs:{"to":"/contact"}},[_vm._v("Contact us")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/blog"}},[_vm._v("News")])],1),_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"col-md-3 col-6"},[_c('strong',[_vm._v("LEGAL")]),_c('ul',[_vm._m(3),_c('li',[_c('RouterLink',{attrs:{"to":"/terms"}},[_vm._v("Terms of Use")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/privacy"}},[_vm._v("Privacy policy")])],1),_c('li',[_c('RouterLink',{attrs:{"to":"/disclaimer"}},[_vm._v("Disclaimer")])],1)])]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row newsletter"},[_c('div',{staticClass:"col-md-6 align-self-center"},[_c('p',{staticClass:"p"},[_vm._v("Get the app")]),_c('ul',{staticClass:"app-store"},[_c('li',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.tokash","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/playstore-img2.png"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/appstore-img2.png"),"alt":""}})])])])]),_c('div',{staticClass:"col-md-6 align-self-center"},[_c('div',{staticClass:"form"},[_c('form',[_c('p',{staticClass:"p"},[_vm._v("Join Now")]),_c('input',{staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Your email"}}),_c('input',{staticClass:"btn-style",attrs:{"onclick":"saveMail('email')","type":"button","value":"Get Started"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://k9654f4dt2i.typeform.com/to/BVJ86WlB?typeform-source=tokash.io"}},[_vm._v("Partnership")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://faq.tokash.io/hc/en-gb"}},[_vm._v("FAQ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://real-time-attest.trustexplorer.io/wallex"}},[_vm._v("View Attestations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 col-12 social-col"},[_c('strong',[_vm._v("Follow Us")]),_c('ul',{staticClass:"footer-social"},[_c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/tokashstablecoin/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-fb-icon.svg"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://twitter.com/Tokash_io","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-twitter-icon.svg"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://www.linkedin.com/company/tokash","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-linkedin-icon.svg"),"alt":""}})])]),_c('br'),_c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/tokash.io/","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-insta-icon.svg"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://t.me/+XtfSkbiacwUzYTBk","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-telegram-icon.svg"),"alt":""}})])]),_c('li',[_c('a',{attrs:{"href":"https://medium.com/@tokash.io","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/images/icons/footer-medium-icon.svg"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("Copyright © 2022 TOKASH")]),_c('p',[_vm._v(" TokashPay Ltd. is a registered MSB with FINTRAC Canada for foreign exchange dealing, money transferring and dealing in virtual currencies with an Incorporation Number: BC1314385 and address 5307 Victoria Drive #676, Vancouver, British Columbia, V5P 3V6, Canada."),_c('br'),_vm._v(" Tokash UAB is a registered as Virtual Currency Exchange Operator and Deposit Virtual Currency Wallet Operator with the Financial crime investigation service as Authority, under Law on the Prevention of Money Laundering and Terrorist Financing of the Republic of Lithuania with a company code: 305916911 and address Laisvės pr. 60, Vilnius 05103, Lithuania"),_c('br'),_vm._v(" Tokash Custody KB is a registered as a Professional Trustee in accordance with the Money Laundering and Counter-Terrorism Financing Prevention Act 2017:630 (AML/CTF Compliance) with Stockholm County Administrative Board as Supervisory Authority for AML/CTF purposes with a reg. number 969794-0097 and address Stockholms Brevboxar 1679, 116 74 Stockholm ")])])
}]

export { render, staticRenderFns }