<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" :playStore="true" primaryBannerTitle="Get Your <br> Tokash Card"
            primaryBannerDescription="Manage easily your cards with Tokash app" secondaryBannerImg="tokashcardmockup" id="card-banner"/>

    <section id="card-receive-rewards">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="img-wrapper">
              <img src="../assets/images/card-banner-image2.png" alt="Receive Rewards Image">
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h2>Receive Rewards</h2>
              <p>Get your Tokenback Reward paid in <br> Stablecoins</p>
              <a href="https://app.tokash.io/auth/sign-up" class="btn-style primary-arrow">Get Started</a>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="atm-steps">
      <div class="container">
        <h2>What You Get</h2>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/card-page-atm-card-icon-2.png" alt="">
              </div>
              <div class="content-wrapper">
                <h3>SPEND YOUR CRYPTO</h3>
                <p>Unlimited Cryptocurrencies Spending</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/eurstIcon.png" alt="">
              </div>
              <div class="content-wrapper">
                <h3>EARN TOKENBACK</h3><br/><br/>
                <p>Instant rewards the moment you top up your card with stablecoins</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="atm-card">
              <div class="img-wrapper">
                <img src="../assets/images/icons/card-page-atm-card-icon-3.svg" alt="">
              </div>
              <div class="content-wrapper">
                <h3>BEST RATES</h3><br/><br/>
                <p>Real-time conversion between all supported currencies</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
  },
  data() {
    return {
    }
  },
  methods: {}
}
</script>
