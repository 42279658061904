<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" primaryBannerTag="Explore Our" primaryBannerTitle="Products" id="product-banner"
            primaryBannerDescription="<strong>Accept Payments, Send, Spend with Card, <br/> Redeem & Stake</strong>
            Your assets & digital assets in the fastest, safest, and <br/> most secure way like never before." secondaryBannerImg="tokash-gears"/>


    <section id="about-how-it-work" class="product-how-word">
      <div class="container">
        <h2 class="d-none d-md-block">Our Products</h2>

        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h3 class="h3">Spend your coins with card</h3>
              <p class="p">The easiest way to spend stablecoins worldwide with instant transactions and payments trackable on your device.</p>
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img class="spendCoinsImg" src="../assets/images/about-goal-image.png" alt="How work">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img style="max-width: 280px;" src="../assets/images/tokash-cardboard.png" alt="How work">
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h3 class="h3">Reward Program</h3>
              <p class="p">Start earning rewards today. Top up your card with stablecoins in a few steps and instantly receive up to 2% token back to your account.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h3 class="h3">Staking</h3>
              <p class="p">Tokash allows you to earn cryptocurrency as a reward for using your existing holdings without gas fees. Start flexible Staking today and get paid on a daily base. Redeem your assets at any time without losing any interest.</p>
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img style="max-width: 250px;
              float: right;" src="../assets/images/stakinghome.png" alt="How work">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/tokashcustody.png" style="max-width: 300px;" alt="How work">
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h3 class="h3">Custody</h3>
              <p class="p">Safe and secure storage solution for your cryptocurrency.
                Reducing the risk and complications associated with managing your
                own crypto assets.</p>
            </div>
          </div>
        </div>

        <div class="row" style="  padding-top: 64px; padding-bottom: 64px;">
          <div class="col-md-6 align-self-center">
            <div class="content-wrapper">
              <h3 class="h3">Exchange</h3>
              <p class="p">Exchange easily at any time all supported cryptocurrencies with real-time conversion.</p>
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div class="img-wrapper">
              <img src="../assets/images/tokashexchange.png" alt="How work">
            </div>
          </div>
        </div>

      </div>
    </section>


    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>
