<template>
  <header id="header" :class="headerClass">
    <div class="container">
      <div class="row">
        <div class="col-md-2 align-self-center">
          <RouterLink to="/" class="logo">
            <img style="max-width: 120px;" src="../assets/images/logo4.svg" alt="Logo">
          </RouterLink>
        </div>
        <span id="menu-btn" @click="addMenuClass" :class="{ 'active' : isMenuActive }"></span>
        <div class="col-md-8 align-self-center">
          <div id="navbar" :class="{ 'active' : isMenuActive }">
            <ul class="menu">
              <li><RouterLink to="/">Home</RouterLink></li>
             <!--<li><a href="https://exchangewallex.com/">Buy Crypto</a></li>-->
              <!--<li><RouterLink to="about">About us</RouterLink></li>-->
              <li>
                <div>
                  <b-dropdown id="dropdown-1" :style="{ color: navbarColors[$route.path] }" text="Solutions">
                    <b-dropdown-item class="hovering"><RouterLink to="product">Overview</RouterLink></b-dropdown-item>
                    <b-dropdown-item class="hovering"><RouterLink to="staking">Staking</RouterLink></b-dropdown-item>
                    <b-dropdown-item class="hovering"><RouterLink to="franchising">Franchising</RouterLink></b-dropdown-item>
                    <b-dropdown-item class="hovering"><RouterLink to="atm">ATM</RouterLink></b-dropdown-item>
                    <!--<b-dropdown-item class="hovering"><RouterLink to="atm">ATM</RouterLink></b-dropdown-item>-->
                  </b-dropdown>
                </div>
              </li>
              <li><a href="https://eurst.io/">Eurst</a></li>
              <li><RouterLink to="pricing">Membership</RouterLink></li>
              <li><RouterLink to="currencies">Currencies</RouterLink></li>
              <li>
                <div>
                  <b-dropdown id="dropdown-2" :style="{ color: navbarColors[$route.path] }" text="About">
                    <b-dropdown-item class="hovering"><RouterLink to="about">About us</RouterLink></b-dropdown-item>
                    <b-dropdown-item class="hovering"><RouterLink to="ambassador">Ambassadors</RouterLink></b-dropdown-item>
                    <b-dropdown-item class="hovering"><RouterLink to="affiliate">Affiliate</RouterLink></b-dropdown-item>
                    <!--<b-dropdown-item class="hovering"><RouterLink to="career">Careers</RouterLink></b-dropdown-item>-->
                  </b-dropdown>
                </div>
              </li>
<!--              <li><a href="#">Other</a>
                <ul class="drop-down">
                  <li><RouterLink to="about-1">About Page 2</RouterLink></li>
                  <li><RouterLink to="about-2">About Page 3</RouterLink></li>
                  <li><RouterLink to="ambassador">Ambassador</RouterLink></li>
                  <li><RouterLink to="blog">Blog</RouterLink></li>
                  <li><RouterLink to="single">Single</RouterLink></li>
                  <li><RouterLink to="affiliate">Affiliate</RouterLink></li>
                  <li><RouterLink to="product">Product</RouterLink></li>
                  <li><RouterLink to="faq">Faq</RouterLink></li>
                  <li><RouterLink to="atm">ATM</RouterLink></li>
                  <li><RouterLink to="card">Card</RouterLink></li>
                  <li><RouterLink to="stack">Stacking</RouterLink></li>
                </ul>
              </li>-->
              <li v-if="isMobile" class="login-mobile"><a href="#">Login</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 align-self-center text-md-right loginDesktop" style="margin-left: -30px;">
          <a href="https://app.tokash.io/auth/sign-in" class="btn-style">Login</a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tokash" class="appIcon first"><img src="../assets/images/Tokash_google_play.png"></a>
          <a target="_blank" href="" class="appIcon"><img src="../assets/images/Tokash_app_store.png"></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      navbarColors: {
        '/': 'red',
        '/about': 'blue',
      },
    }
  },
}
</script>

<script>
// @ is an alias to /src
export default {
  name: 'Header',
  props: ['headerClass'],
  components: {
  },
  data(){
    return{
      isMenuActive: false,
      navbarColors: {
        '/': '',
        '/about': 'white',
      },
    }
  },
  methods:{
    addMenuClass(){
      this.isMenuActive = !this.isMenuActive
      if (this.isMenuActive){
        document.body.classList.add('active')
      }else {
        document.body.classList.remove('active')
      }
    },
    handleSCroll() {
      let header = document.querySelector("header");
      if (window.scrollY >= 100 && !header.className.includes('sticky')) {
        header.classList.add('sticky');
      } else if (window.scrollY <= 100) {
        header.classList.remove('sticky');
      }
    },
    isMobile() {
      if (screen.width <= 600) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleSCroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleSCroll);
  }
}
</script>
