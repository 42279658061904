<template>
  <div class="home bluebg">
    <Header/>

    <Banner secondaryBanner="active" buttonLink="" :playStore="false" primaryBannerTitle="Contacts"
            primaryBannerDescription="Our team is working on delivering the best customer service to you.
            Message us on the according department channel to help us get back to you faster than usual. <br><br>
            In case you don't find the according channel, get in touch with us through Intercom."
            secondaryBannerImg="contact-us-banner-image" id="contact-banner"/>

    <section id="for-enquiries">
      <div class="container">
        <h2>For Enquiries</h2>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">Intercom</h3>
              <p class="p">For any kind of enquiry for
                platform functionalities, you can live
                chat with us on our intercom channel <a href="#">here</a></p>
<!--              <a href="mailto:info@tokash.io">info@tokash.io</a>-->
            </div>
          </div>
          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">General</h3>
              <p class="p">For any general enquiries, you can email us at:</p>
              <a href="mailto:info@tokash.io">info@tokash.io</a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">Sales</h3>
              <p class="p">For any enquires, new partnerships
                or business opportunities, you can email us at:</p>
              <a href="mailto:sales@tokash.io">sales@tokash.io</a>
            </div>
          </div>

          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">Press</h3>
              <p class="p">For any enquiries related to
                marketing, press or information needed about
                Tokash, you can email us at:</p>
              <a href="mailto:info@tokash.io">press@tokash.io</a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">Affiliates</h3>
              <p class="p">For any enquires related
                to our Affiliates program, you can
                email us at:</p>
              <a href="mailto:affiliates@tokash.io">affiliates@tokash.io</a>
            </div>
          </div>
          <div class="col-md-4">
            <div class="enquiries-card">
              <h3 class="h3">Ambassadors</h3>
              <p class="p">For any enquires, new partnerships or
                business opportunities, you can email us at:</p>
              <a href="mailto:ambassadors@tokash.io">ambassadors@tokash.io</a>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="for-more-enquiries">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="enquiries-card">
              <h3 class="h3">Learn more</h3>
              <p class="p">Read more about the game-changing
                Tokash news in more detail on our</p>
              <a href="#"><RouterLink to="blog">News section.</RouterLink></a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="enquiries-card">
              <h3 class="h3">More questions?</h3>
              <p class="p">You can find everything
                you need to know on the</p>
              <RouterLink to="faq">Help Centre.</RouterLink>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
  },
  data() {
    return {
    }
  },
  methods: {}
}
</script>
