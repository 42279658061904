<template>
  <div class="home bluebg">
    <Header/>
    <Banner secondaryBanner="active" buttonLink="" :playStore="false" primaryBannerTag="Earn Up To" primaryBannerTitle="14%"
            primaryBannerDescription="on your crypto" secondaryBannerImg="stacking-banner-img" id="stacking-banner"/>

    <section id="stacking-crypto">
      <div class="container text-center">
        <p class="p">STAKING CRYPTO</p>
        <h2>WHY CHOOSE TOKASH?</h2>
        <div class="row row-eq-height">
          <div class="col-md-4">
            <div class="stacking-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/stacking-card-img-1.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <h3>Passive Income</h3>
               <p>Put your digital assets
                 to work and earn passive income paid
                 out on a daily basis.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="stacking-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/icons/stacking-card-img-2.svg" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <h3>High Security</h3>
                <p>Tokash provides state-of-the-art
                  security to guarantee the safety
                  of your assets.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="stacking-card">
              <div class="img-wrapper">
                <div class="circle">
                  <img src="../assets/images/hand.png" alt="Icon">
                </div>
              </div>
              <div class="content-wrapper">
                <h3>Easy to Use</h3>
                <p>Staking on Tokash is intuitive
                  and you can get started in minutes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="stacking-plans">
      <div class="container">
        <p>WHAT ARE THE NUMBERS?</p>
        <h2>COMPARE IT IN OUR PLANS</h2>
        <ul>
          <li v-on:click="activeTab='1'" v-bind:class="[ activeTab === '1' ? 'active' : '' ]">STANDARD</li>
          <li v-on:click="activeTab='2'" v-bind:class="[ activeTab === '2' ? 'active' : '' ]">PREMIUM</li>
          <li v-on:click="activeTab='3'" v-bind:class="[ activeTab === '3' ? 'active' : '' ]">ELITE</li>
        </ul>

        <div class="stacking-card-main-wrapper">
          <div v-if="activeTab ==='1'" class="stacking-plans-card-wrapper">
            <div class="row row-eq-height">
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-1.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin</h3>
                      <p class="p">Earn BTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-2.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Solana</h3>
                      <p class="p">Earn Sol interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-3.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin cash</h3>
                      <p class="p">Earn BCH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-10.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDP</h3>
                      <p class="p">Earn USDP interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-11.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Tether</h3>
                      <p class="p">Earn USDT interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-12.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>DAI</h3>
                      <p class="p">Earn DAI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-13.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USD Coin</h3>
                      <p class="p">Earn USDC nterest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-14.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>TUSD</h3>
                      <p class="p">Earn TUSD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-15.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Fei USD</h3>
                      <p class="p">Earn FEI USD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-4.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Gemini</h3>
                      <p class="p">Earn GEMINI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-5.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>EURST</h3>
                      <p class="p">Earn EURST interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>4.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-6.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDD</h3>
                      <p class="p">Earn USDD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-7.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Litecoin</h3>
                      <p class="p">Earn LTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-8.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>BUSD</h3>
                      <p class="p">Earn BUSD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-9.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Ethereum</h3>
                      <p class="p">Earn ETH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-16.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Dash</h3>
                      <p class="p">Earn Dash interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>1.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab ==='2'" class="stacking-plans-card-wrapper">
            <div class="row row-eq-height">
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-1.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin</h3>
                      <p class="p">Earn BTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-2.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Solana</h3>
                      <p class="p">Earn Sol interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-3.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin cash</h3>
                      <p class="p">Earn BCH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-10.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDP</h3>
                      <p class="p">Earn USDP interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-11.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Tether</h3>
                      <p class="p">Earn USDT interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-12.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>DAI</h3>
                      <p class="p">Earn DAI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-13.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USD Coin</h3>
                      <p class="p">Earn USDC nterest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-14.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>TUSD</h3>
                      <p class="p">Earn FEI USD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-15.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Fei USD</h3>
                      <p class="p">Earn FEI USD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-4.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Gemini</h3>
                      <p class="p">Earn GEMINI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-5.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>EURST</h3>
                      <p class="p">Earn EURST interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>9.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-6.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDD</h3>
                      <p class="p">Earn USDD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-7.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Litecoin</h3>
                      <p class="p">Earn LTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-8.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>BUSD</h3>
                      <p class="p">Earn BUSD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-9.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Ethereum</h3>
                      <p class="p">Earn ETH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>3.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-16.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Dash</h3>
                      <p class="p">Earn Dash interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>2.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab ==='3'" class="stacking-plans-card-wrapper">
            <div class="row row-eq-height">
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-1.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin</h3>
                      <p class="p">Earn BTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-2.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Solana</h3>
                      <p class="p">Earn Sol interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-3.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Bitcoin cash</h3>
                      <p class="p">Earn BCH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-10.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDP</h3>
                      <p class="p">Earn USDP interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-11.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Tether</h3>
                      <p class="p">Earn USDT interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-12.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>DAI</h3>
                      <p class="p">Earn DAI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>9.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-13.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USD Coin</h3>
                      <p class="p">Earn USDC nterest</p>
                    </div>
                    <div class="card-percent">
                      <strong>9.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-14.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>TUSD</h3>
                      <p class="p">Earn FEI USD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-15.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Fei USD</h3>
                      <p class="p">Earn FEI USD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-4.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Gemini</h3>
                      <p class="p">Earn GEMINI interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-5.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>EURST</h3>
                      <p class="p">Earn EURST interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>14.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-6.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>USDD</h3>
                      <p class="p">Earn USDD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-7.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Litecoin</h3>
                      <p class="p">Earn LTC interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-8.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>BUSD</h3>
                      <p class="p">Earn BUSD interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-9.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Ethereum</h3>
                      <p class="p">Earn ETH interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>6.5%</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="stacking-plans-card">
                  <div class="img-wrapper">
                    <img src="../assets/images/icons/stacking-plans-card-icon-16.svg" alt="">
                  </div>
                  <div class="content-wrapper">
                    <div class="card-name">
                      <h3>Dash</h3>
                      <p class="p">Earn Dash interest</p>
                    </div>
                    <div class="card-percent">
                      <strong>5.0%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Annual precentages paid on a daily base</p>
        </div>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner
  },
  data() {
    return {
      activeTab: '1',
    }
  },
  methods: {}
}
</script>
