<template>
  <footer id="footer">
    <div class="container">
      <div class="logo">
        <RouterLink to="/"><img style="max-width: 120px;" src="../assets/images/tokash-white.svg" alt=""></RouterLink>
      </div>
      <div class="row newsletter">
        <div class="col-md-6 align-self-center">
          <p class="p">Get the app</p>
          <ul class="app-store">
            <li><a href="https://play.google.com/store/apps/details?id=com.tokash" target="_blank"><img src="../assets/images/playstore-img2.png" alt=""></a></li>
            <li><a href="" target="_blank"><img src="../assets/images/appstore-img2.png" alt=""></a></li>
          </ul>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="form">
            <form>
              <p class="p">Join Now</p>
              <input id="email" type="text" placeholder="Your email" class="form-control">
              <input onclick="saveMail('email')" type="button" value="Get Started" class="btn-style">
            </form>
          </div>
        </div>
      </div>
      <div class="row footer-links">
        <div class="col-md-3 col-6">
          <strong>PRODUCTS</strong>
          <ul>
            <li><RouterLink to="/card">Cards</RouterLink></li>
            <li><RouterLink to="/staking">Staking</RouterLink></li>
            <li><RouterLink to="/product">Products</RouterLink></li>
            <li><RouterLink to="/pricing">Pricing</RouterLink></li>
          </ul>
        </div>
        <div class="col-md-3 col-6">
          <strong>ABOUT US</strong>
          <ul class="coming-soon">
            <li><RouterLink to="/contact">Contact us</RouterLink></li>
            <li><RouterLink to="/blog">News</RouterLink></li>
            <li><a href="https://k9654f4dt2i.typeform.com/to/BVJ86WlB?typeform-source=tokash.io">Partnership</a></li>
            <!--<li><RouterLink to="faq">FAQ</RouterLink></li>-->
            <li><a href="https://faq.tokash.io/hc/en-gb">FAQ</a></li>
          </ul>
        </div>
        <div class="col-md-3 col-6">
          <strong>LEGAL</strong>
          <ul>
            <li><a href="https://real-time-attest.trustexplorer.io/wallex">View Attestations</a></li>
            <li><RouterLink to="/terms">Terms of Use</RouterLink></li>
            <li><RouterLink to="/privacy">Privacy policy</RouterLink></li>
            <li><RouterLink to="/disclaimer">Disclaimer</RouterLink></li>
          </ul>
        </div>
        <div class="col-md-3 col-12 social-col">
          <strong>Follow Us</strong>
          <ul class="footer-social">
            <li><a href="https://www.facebook.com/tokashstablecoin/" target="_blank"><img src="../assets/images/icons/footer-fb-icon.svg" alt=""></a></li>
            <li><a href="https://twitter.com/Tokash_io" target="_blank"><img src="../assets/images/icons/footer-twitter-icon.svg" alt=""></a></li>
            <li><a href="https://www.linkedin.com/company/tokash" target="_blank"><img src="../assets/images/icons/footer-linkedin-icon.svg" alt=""></a></li>
            <br>
            <li><a href="https://www.instagram.com/tokash.io/" target="_blank"><img src="../assets/images/icons/footer-insta-icon.svg" alt=""></a></li>
            <li><a href="https://t.me/+XtfSkbiacwUzYTBk" target="_blank"><img src="../assets/images/icons/footer-telegram-icon.svg" alt=""></a></li>
            <li><a href="https://medium.com/@tokash.io" target="_blank"><img src="../assets/images/icons/footer-medium-icon.svg" alt=""></a></li>
          </ul>
<!--          <ul class="footer-social">

          </ul>-->
        </div>
      </div>
      <div class="copyright">
        <p>Copyright © 2022 TOKASH</p>
        <p> 

          TokashPay Ltd. is a registered MSB with FINTRAC Canada for foreign exchange dealing, money transferring and dealing in virtual currencies with an Incorporation Number: BC1314385 and address 5307 Victoria Drive #676, Vancouver, British Columbia, V5P 3V6, Canada.<br/>
          Tokash UAB is a registered as Virtual Currency Exchange Operator and Deposit Virtual Currency Wallet Operator with the Financial crime investigation service as Authority, under Law on the Prevention of Money Laundering and Terrorist Financing of the Republic of Lithuania with a company code: 305916911 and address Laisvės pr. 60, Vilnius 05103, Lithuania<br/>
          Tokash Custody KB is a registered as a Professional Trustee in accordance with the Money Laundering and Counter-Terrorism Financing Prevention Act 2017:630 (AML/CTF Compliance) with Stockholm County Administrative Board as Supervisory Authority for AML/CTF purposes with a reg. number 969794-0097 and address Stockholms Brevboxar 1679, 116 74 Stockholm
          
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Header',
  components: {
  }
}
</script>
